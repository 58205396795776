<template >
  <div
    v-if="socketConnectionLoading || crudRulesLoading"
    :class="[
      crudRulesLoading ? 'saving-overlay' : '',
      'establishing-connection-overlay',
    ]"
  >
    <div>
      <div v-if="crudRulesLoading" class="d-flex flex-column align-center">
        <Funny-loading />
        <p class="mt-4">Salvando alterações</p>
      </div>

      <div v-else>
        <Prd-loading-circular size="50" />
        <p>Conectando...</p>
      </div>
    </div>
  </div>
</template>

<script>
import FunnyLoading from "@/components/common/funny-loading.vue";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";

export default {
  components: {
    FunnyLoading,
    PrdLoadingCircular,
  },

  computed: {
    crudRulesLoading() {
      return this.$store?.state?.copilotStore?.crudRulesLoading ?? false;
    },
    socketConnectionLoading() {
      return this.$store?.state?.copilotStore?.socketConnectionLoading ?? false;
    },
  },
};
</script>

<style lang="scss" scoped>
.establishing-connection-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000000;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: #ffffffab;
  display: flex;
  justify-content: center;
  align-items: center;
}

.saving-overlay {
  background-color: #fff;
}
</style>
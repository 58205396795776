// copilot-store.js
import signalService from '../../socket';
import i18n from '@/i18n'
import { createMessage } from "./copilot-utils"
import { createRuleInGroup, createRuleGroup, deleteRuleFromGroup, deleteGroupRules } from "./copilot-handle-promisses"

export default {
    state: () => ({
        isOpen: false,
        copilotChatLoading: false,
        socketConnectionLoading: false,
        crudRulesLoading: false,
        copilotOperations: [],
        chats: [],
    }),

    getters: {
        showConfirmationRules: (state) => {
            if (state.copilotOperations.some(item => item.Error || item.OperationType == -1)) return false

            return state.copilotOperations.some((el) => [0, 1, 2, 4].includes(el.OperationType));
        },

        disableRulesActions: (state, getters) => {
            return state.crudRulesLoading || getters.showConfirmationRules || state.copilotChatLoading;
        }
    },

    mutations: {
        TOGGLE_COPILOT_MODAL(state, value) {
            state.isOpen = value
        },

        TOGGLE_SOCKET_CONNECTION_LOADING(state, loading) {
            state.socketConnectionLoading = loading
        },

        TOGGLE_COPILOT_TYPING_LOADING(state, loading) {
            state.copilotChatLoading = loading
        },

        RESET_CHATS(state) {
            state.chats = []
        },

        INSERT_COPILOT_OPERATIONS(state, payload) {
            state.copilotOperations = payload
        },

        TOGGLE_CRUD_RULES_LOADING(state, status) {
            state.crudRulesLoading = status
        },

        /**
       * Add a new message to the chat.
       * @param {Object} payload - Messages object
       * @param {string} payload.author - Message author
       * @param {Array<Object>} payload.messages - Message list
       * @param {string} payload.messages[].type - Type of message, can be text,audio,archive
       * @param {string} payload.messages[].message - Message content
       */
        ADD_MESSAGE(state, payload) {
            state.chats.push(payload);
        }
    },

    actions: {
        async INITIALIZE_SOCKET({ rootState, commit, dispatch }) {
            commit('TOGGLE_SOCKET_CONNECTION_LOADING', true)
            commit('INSERT_COPILOT_OPERATIONS', [])

            commit('RESET_CHATS')
            commit('TOGGLE_COPILOT_TYPING_LOADING', false)

            try {
                const token = rootState?.userStore?.token ?? null
                const socket = await signalService.initializeSocket(token);

                if (socket._connectionState != 'Connected') {
                    dispatch('HANDLE_ERROR')
                    return
                }

                // Registering events on socket.js
                signalService.on('ReceiveMessage', (user, message) => dispatch('RECEIVE_MESSAGE', { user, message }))

            } catch (error) {
                dispatch('HANDLE_ERROR')
            }

            commit('TOGGLE_SOCKET_CONNECTION_LOADING', false)
        },

        async SEND_MESSAGE({ commit }, payload) {
            commit('INSERT_COPILOT_OPERATIONS', [])
            commit('TOGGLE_COPILOT_TYPING_LOADING', true)
            commit('ADD_MESSAGE', payload)

            try {
                await signalService.send('SendMessage', payload.idCompany, payload.messages[0].message)
            } catch (error) {
                commit('TOGGLE_COPILOT_TYPING_LOADING', false)
                commit('snackbarV2/set', { message: i18n.t('TXT_COPILOT_ERROR_SEND_MESSAGE'), type: 'error' }, { root: true });
                commit('ADD_MESSAGE', createMessage('Copilot', 'Ocorreu um erro, tente novamente'))
            }
        },

        RECEIVE_MESSAGE({ commit }, payload) {
            const parts = payload.message.split('').filter(part => part.trim() !== '');
            let objects = parts.map(part => JSON.parse(part));

            const operations = objects[0]?.Operations || [];
            commit('INSERT_COPILOT_OPERATIONS', operations)

            const operationHasError = operations.some(el => el.Error || el.OperationType == -1)

            operations.forEach(async (operation) => {
                if (operationHasError) commit('ADD_MESSAGE', createMessage('Copilot', operation.Output))
                if (!operationHasError && !operation.Error) commit('ADD_MESSAGE', createMessage('Copilot', operation.Output))
            })

            commit('TOGGLE_COPILOT_TYPING_LOADING', false)
        },

        async CONFIRM_RULES({ state, commit, dispatch }, payload) {
            commit('ADD_MESSAGE', createMessage(payload.userName, "Sim"))
            commit('TOGGLE_CRUD_RULES_LOADING', true)

            const corectOperations = state.copilotOperations.filter((el) => !el.Error || el.OperationType != 3 || el.OperationType != -1)

            const operationsPromises = corectOperations.map((operation) => {

                let operationData = {
                    operation: operation,
                    idCompany: payload.idCompany
                }

                if (operation.Error || operation.OperationType == 3 || operation.OperationType == -1) return

                if (operation.OperationType == 0) return createRuleGroup(operationData)

                if (operation.OperationType == 1) return deleteGroupRules(operationData)

                if (operation.OperationType == 2) return createRuleInGroup(operationData);

                if (operation.OperationType == 4) return deleteRuleFromGroup(operationData)
            });

            dispatch('HANDLE_PROMISSES', operationsPromises)
        },

        async HANDLE_PROMISSES({ commit, dispatch, state }, operationsPromises) {
            try {
                await Promise.all(operationsPromises);
                commit('ADD_MESSAGE', createMessage('Copilot', "Sucesso ao realizar alterações solicitadas"));
                dispatch('getRules', { fromCopilot: true, newOperations: state.copilotOperations }, { root: true });
                commit('INSERT_COPILOT_OPERATIONS', [])
            } catch (error) {
                console.log('ERRO NAS PROMESSAS')
                commit('ADD_MESSAGE', createMessage('Copilot', "Erro ao realizar alterações solicitadas"));
            }

            commit('TOGGLE_CRUD_RULES_LOADING', false);
        },

        DENIED_RULES({ commit }, author) {
            commit('INSERT_COPILOT_OPERATIONS', [])

            commit('ADD_MESSAGE', createMessage(author, 'Não'))
            commit('ADD_MESSAGE', createMessage('Copilot', 'Posso te ajudar em mais alguma coisa?'))
        },

        HANDLE_ERROR({ commit }) {
            commit('INSERT_COPILOT_OPERATIONS', [])
            commit('snackbarV2/set', { message: i18n.t('TXT_COPILOT_ERROR_CONNECT'), type: 'error' }, { root: true });
            commit('TOGGLE_COPILOT_MODAL', false)
        },
    }
};

import * as signalR from '@microsoft/signalr';

let connection

export default {
    initializeSocket: async (token) => {
        try {
            connection = new signalR.HubConnectionBuilder()
                .withUrl('https://rulemanager-api-pre-prod.azurewebsites.net/ws/copilot', { accessTokenFactory: () => token })
                .configureLogging(signalR.LogLevel.Information)
                .build();

            await connection.start();

            console.log('[initializeSocket] SignalR connection established.');

            return connection;
        } catch (error) {
            console.error('[initializeSocket] Error initializing SignalR connection:', error);
            throw error;
        }
    },

    on: (eventName, callback) => {
        connection.on(eventName, callback);
    },


    send: async (methodName, ...args) => {
        try {
            await connection.invoke(methodName, ...args);
        } catch (error) {
            console.error('[send] Error sending message', error);
            throw error;
        }
    },

    stopConnection: async () => {
        try {
            await connection.stop();
            console.log('[stopConnection] SignalR disconnected');
        } catch (err) {
            console.error('[stopConnection] Error disconnected to SignalR', err);
            setTimeout(() => connection.start(), 5000);
        }
    },
}



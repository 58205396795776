<template>
  <v-row no-gutters align="center" class="gap-8">
    <Prd-search
      v-model="emailOrName"
      :searchPlaceholder="$t('TXT_SEARCH_2')"
      :searchLabel="$t('TXT_SEARCH_FOR_NAME_OR_EMAIL')"
      :disabled="isLoading || originalList.length <= 1"
      :delay="0"
    />
    <Prd-combo-box
      :title="$t('TXT_USER_TYPE')"
      :items="userTypesItems"
      :placeholder="$t('TXT_ALL')"
      :disabled="isLoading || originalList.length <= 1"
      @input="isAdmin = $event.value"
    />
  </v-row>
</template>

<script>
import PrdComboBox from "@/Design_System/common/prd-comboBox.vue";
import PrdSearch from "@/components/common/prd-search.vue";

export default {
  components: { PrdComboBox, PrdSearch },
  props: ["isLoading", "originalList"],
  data() {
    return {
      userTypesItems: [
        {
          text: this.$i18n.t("TXT_ALL"),
          value: false,
        },
        {
          text: this.$i18n.t("TXT_ADMIN"),
          value: true,
        },
      ],
      emailOrName: "",
      isAdmin: false,
    };
  },
  methods: {
    filterUSers() {
      let filteredList = JSON.parse(JSON.stringify(this.originalList));
      if (this.emailOrName != null || this.emailOrName != "") {
        filteredList = filteredList.filter(
          (user) =>
            user.nome
              .toString()
              .toLowerCase()
              .includes(this.emailOrName.toString().toLowerCase()) ||
            user.email
              .toString()
              .toLowerCase()
              .includes(this.emailOrName.toString().toLowerCase())
        );
      }
      if (this.isAdmin) {
        const adminRolesSet = new Set(["1000", "1001", "1002", "1003"]);
        filteredList = filteredList.filter((user) =>
          user.adminRoles.some((role) => adminRolesSet.has(role))
        );
      }
      this.$emit("filteredList", filteredList);
    },
  },
  watch: {
    originalList: {
      handler() {
        this.filterUSers();
      },
      immediate: true,
      deep: true,
    },
    emailOrName: {
      handler() {
        this.filterUSers();
      },
      immediate: true,
    },
    isAdmin: {
      handler() {
        this.filterUSers();
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
<template>
  <div class="ml-1 mt-2 confirmation-box">
    <p class="pa-0 ma-0">{{ $i18n.t("TXT_CONFIRM_CHANGES") }}</p>
    <div @click="handleAnswer('Não')" class="no">
      {{ $i18n.t("TXT_NO") }}
    </div>
    <div @click="handleAnswer('Sim')" class="yes">
      {{ $i18n.t("TXT_YES") }}
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    userData() {
      return this.$store?.getters?.userData ?? null;
    },
    copilotOperations() {
      return this.$store?.state?.copilotStore?.copilotOperations ?? null;
    },
  },

  methods: {
    handleAnswer(text) {
      if (text === "Não")
        this.$store.dispatch("DENIED_RULES", this.userData.username);
      else
        this.$store.dispatch("CONFIRM_RULES", {
          userName: this.userData.username,
          idCompany: this.userData.idCompany,
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.confirmation-box {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  div {
    padding: 4px 8px;
    width: 50px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    transition: 0.2s;
  }

  .yes {
    border: 0.5px solid #1d8527;
  }

  .yes:hover {
    background-color: #dafdde;
  }

  .no {
    border: 0.5px solid #b00020;
  }

  .no:hover {
    background-color: #e5c5cb;
  }
}
</style>
<template>
  <div>
    <span @click.stop="modalOpen = true" class="validation-button mr-4">
      <v-icon :class="setStatusColor(lastAction)"
        >mdi-traffic-light-outline</v-icon
      >
      <span :class="setStatusColor(lastAction)">{{
        $i18n.t("TXT_RULECONDITION")
      }}</span>
    </span>

    <v-dialog v-model="modalOpen" persistent>
      <v-card class="pa-4">
        <v-row
          no-gutters
          align="center"
          justify="space-between"
          class="mb-4 pt-4"
        >
          <h4>{{ $i18n.t("TXT_RULECONDITION").toUpperCase() }}</h4>
          <v-icon @click="modalOpen = false">mdi-close</v-icon>
        </v-row>

        <div class="modal-title mb-4 py-2">
          <span class="font-weight-bold">{{ productName }}</span>
        </div>

        <Prd-loading-circular v-if="isLoading" />

        <v-data-table
          v-else
          :headers="headers"
          :items="products"
          :footer-props="footerProps"
          :hide-default-footer="products.length <= 5"
          :items-per-page="5"
        >
          <template v-slot:[`item.oldPrice`]="{ item }">
            <td class="text-center">
              {{ formatMonetary(item.oldPrice) }}
            </td>
          </template>

          <template v-slot:[`item.currentPrice`]="{ item }">
            <td class="text-center">
              {{ formatMonetary(item.currentPrice) }}
            </td>
          </template>

          <template v-slot:[`item.oldMargin`]="{ item }">
            <td class="text-center">
              {{ formatPercentage(item.oldMargin) }}
            </td>
          </template>

          <template v-slot:[`item.currentMargin`]="{ item }">
            <td class="text-center">
              {{ formatPercentage(item.currentMargin) }}
            </td>
          </template>

          <template v-slot:[`item.nameCondition`]="{ item }">
            <td class="text-center">
              {{ $t(item.nameCondition) }}
            </td>
          </template>

          <template v-slot:[`item.statusMessage`]="{ item }">
            <td class="text-center" :class="setStatusColor(item.statusMessage)">
              {{ $t(item.statusMessage) }}
            </td>
          </template>

          <template v-slot:[`item.messageBlockedRuleCondition`]="{ item }">
            <td class="text-center">
              {{
                item.messageBlockedRuleCondition
                  ? $t(item.messageBlockedRuleCondition)
                  : "-"
              }}
            </td>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import service from "@/service/pricer/product-list.js";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
const Service = new service();
import { formatMonetary } from "@/utils/format-toMonetary.js";
import { formatPercentage } from "@/utils/format-percentage.js";

export default {
  components: { PrdLoadingCircular },
  props: ["idEnterprisePricesProjection", "productName", "lastAction"],
  data() {
    return {
      modalOpen: false,
      isLoading: false,

      headers: [
        {
          text: this.$i18n.t("TXT_ORDER"),
          align: "center",
          sortable: true,
          value: "order",
        },
        {
          text: this.$i18n.t("TXT_APPLIED_RULE"),
          align: "center",
          sortable: true,
          value: "nameCondition",
          width: "170px",
        },
        {
          text: this.$i18n.t("TXT_PRE_PRICE"),
          align: "center",
          sortable: true,
          value: "oldPrice",
        },
        {
          text: this.$i18n.t("TXT_POST_PRICE"),
          align: "center",
          sortable: true,
          value: "currentPrice",
        },
        {
          text: this.$i18n.t("TXT_PRE_MARGIN"),
          align: "center",
          sortable: true,
          value: "oldMargin",
        },
        {
          text: this.$i18n.t("TXT_POST_MARGIN"),
          align: "center",
          sortable: true,
          value: "currentMargin",
        },
        {
          text: this.$i18n.t("TXT_STATUS"),
          align: "center",
          sortable: true,
          value: "statusMessage",
        },
        {
          text: this.$i18n.t("TXT_REASON"),
          align: "center",
          sortable: true,
          value: "messageBlockedRuleCondition",
        },
      ],

      products: [],

      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },
    };
  },

  watch: {
    modalOpen: {
      handler(value) {
        if (value) this.getRulePath();
      },
      immediate: true,
    },
  },

  methods: {
    formatPercentage,
    formatMonetary,

    async getRulePath() {
      this.isLoading = true;

      try {
        let response = await Service.getRulePath(
          this.idEnterprisePricesProjection
        );

        this.products = response?.data?.result ?? [];
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR"),
          type: "error",
        });
      }

      this.isLoading = false;
    },

    setStatusColor(status) {
      if (status == "TXT_APPROVED" || status == 1) return "success-color";
      else if (status == "TXT_NOT_APPLIED" || status == 2) return "";
      return "error-color";
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-title {
  text-align: center;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.validation-button {
  display: flex;
  align-items: center;
  :hover {
    opacity: 0.5;
  }
}

.success-color {
  color: #1d8527;
}
.error-color {
  color: #b00020;
}
</style>
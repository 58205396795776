<template>
  <v-card class="main-grid pa-3">
    <h4 class="mb-2">{{ $i18n.t("TXT_SUGGESTED_PRICE").toUpperCase() }}</h4>

    <Prd-loading-circular v-if="isLoading" class="div-centered" />

    <div v-if="!isLoading" class="value-box">
      <div class="value">
        <p v-t="'TXT_MARGIN'"></p>
        <v-skeleton-loader
          v-if="loadingSkeleton"
          type="button"
          :height="20"
          class="mt-1"
        />
        <span v-else :class="switchColors(margin)"
          >{{ switchSigns(margin) }} {{ formatPercentage(margin) }}</span
        >
      </div>
      <div class="value">
        <p v-t="'TXT_VARIATION_DEMAND'"></p>

        <v-skeleton-loader
          v-if="loadingSkeleton"
          type="button"
          :height="20"
          class="mt-1"
        />
        <span v-else :class="switchColors(demandVariation)"
          >{{ switchSigns(demandVariation) }}
          {{ formatPercentage(demandVariation) }}</span
        >
      </div>
    </div>
    <div v-if="!isLoading" class="value-box">
      <div class="value">
        <p>IC</p>

        <v-skeleton-loader
          v-if="loadingSkeleton"
          type="button"
          :height="20"
          class="mt-1"
        />
        <span v-else :class="switchColors(competitivenessIndex, true)">{{
          formatPercentage(competitivenessIndex * 100)
        }}</span>
      </div>
      <div class="value">
        <p v-t="'TXT_CURVE'"></p>
        <v-skeleton-loader
          v-if="loadingSkeleton"
          type="button"
          :height="20"
          class="mt-1"
        />
        <span v-else :class="switchColors(abcCurve, true)">{{ abcCurve }}</span>
      </div>
    </div>
    <div v-if="!isLoading" class="value-box">
      <div class="value">
        <p v-t="'TXT_BILLING_VARIANTION_SHORT'"></p>
        <v-skeleton-loader
          v-if="loadingSkeleton"
          type="button"
          :height="20"
          class="mt-1"
        />
        <span v-else :class="switchColors(saleVariation)">{{
          formatMonetary(saleVariation)
        }}</span>
      </div>
      <div class="value">
        <p v-t="'TXT_VARIATION_PROFIT'"></p>
        <v-skeleton-loader
          v-if="loadingSkeleton"
          type="button"
          :height="20"
          class="mt-1"
        />
        <span v-else :class="switchColors(profitVariation)">{{
          formatMonetary(profitVariation)
        }}</span>
      </div>
    </div>
  </v-card>
</template>

<script>
import service from "@/service/pricer/product-list.js";
const Service = new service();
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
import { formatMonetary } from "@/utils/format-toMonetary.js";
import { formatPercentage } from "@/utils/format-percentage.js";
export default {
  components: { PrdLoadingCircular },
  props: ["idEnterprisePricesProjection"],
  data() {
    return {
      isLoading: false,
      loadingSkeleton: false,
      data: null,
    };
  },

  computed: {
    margin() {
      return this.data?.margin ?? "-";
    },
    demandVariation() {
      return this.data?.demandVariation ?? "-";
    },
    competitivenessIndex() {
      return this.data?.competitivenessIndex ?? "-";
    },
    abcCurve() {
      return this.data?.abcCurve ?? "-";
    },
    saleVariation() {
      return this.data?.saleVariation ?? "-";
    },
    profitVariation() {
      return this.data?.profitVariation ?? "-";
    },
  },

  methods: {
    formatMonetary,
    formatPercentage,

    async getPricingInfo(isFromOptimizedPrice) {
      if (!isFromOptimizedPrice) this.isLoading = true;
      else this.loadingSkeleton = true;

      try {
        let response = await Service.getPricingInfo(
          this.idEnterprisePricesProjection
        );
        this.data = response?.data?.result ?? null;
        this.$emit("setMarginToHeader", this.data?.margin);

      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_GET_SUGGESTED_PRICE"),
          type: "error",
        });
        this.data = null;
      }

      this.isLoading = false;
      this.loadingSkeleton = false;
    },

    switchSigns(value) {
      return value >= 0 ? "+" : "";
    },

    switchColors(value, isInfo) {
      if (isInfo) return "neutral-value";

      if (value >= 0) return "positive-value";
      if (value < 0) return "negative-value";
    },
  },
  created() {
    this.getPricingInfo();
  },
};
</script>

<style lang="scss" scoped>
.div-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
p {
  margin: 0;
  padding: 0;
  font-size: 12px;
  margin-bottom: 8px;
}
span {
  font-size: 18px;
  font-weight: bold;
}
.main-grid {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  flex-basis: 1;
  position: relative;

  .value-box {
    display: flex;
    gap: 0.7rem;
    flex-grow: 1;

    .value {
      display: flex;
      flex-direction: column;
      width: 50%;
      box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
        0px 2px 4px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);

      padding: 8px;
    }
  }
}

.negative-value {
  color: $feedback-color-warning-pure !important;
}
.positive-value {
  color: $feedback-color-success-pure !important;
}
.neutral-value {
  color: $brand-color-primary-pure;
}
</style>
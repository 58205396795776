<template>
  <div>
    <v-icon
      :color="$prdStyles('color-primary')"
      @click="modalOpen = true"
      size="30"
      right
      >mdi-arrow-right-bold-box</v-icon
    >
    <v-dialog v-model="modalOpen" persistent>
      <v-card class="pa-4 pt-0">
        <v-row
          no-gutters
          align="center"
          justify="space-between"
          class="mb-4 pt-4"
        >
          <h4>{{ $i18n.t("TXT_COMPETITOR_PRICE").toUpperCase() }}</h4>
          <v-icon
            @click="modalOpen = false"
            >mdi-close</v-icon
          >
        </v-row>

        <div class="modal-title mb-4 py-2">
          <span class="font-weight-bold">{{ productName }}</span>
        </div>

        <Prd-loading-circular v-if="isLoading" class="mt-4" />

        <v-data-table
          v-else
          :headers="headers"
          :items="products"
          :footer-props="footerProps"
          :hide-default-footer="products.length <= 5"
          :items-per-page="5"
        >
          <template v-slot:[`item.price`]="{ item }">
            <td class="text-center">
              {{ formatMonetary(item.price) }}
            </td>
          </template>
          <template v-slot:[`item.refDate`]="{ item }">
            <td class="text-center">
              {{ formatDate(item.refDate) }}
            </td>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { formatMonetary } from "@/utils/format-toMonetary.js";
import { formatDate } from "@/utils/prd-format-date.js";
import service from "@/service/pricer/product-list.js";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
const Service = new service();
export default {
  components: { PrdLoadingCircular },
  props: ["idEnterprisePricesProjection", "productName"],
  data() {
    return {
      modalOpen: false,
      isLoading: false,

      headers: [
        {
          text: this.$i18n.t("TXT_PRODUCT"),
          align: "center",
          sortable: true,
          value: "productName",
          width: "300px",
        },
        {
          text: this.$i18n.t("TXT_BRAND"),
          align: "center",
          sortable: true,
          value: "brand",
          width: "170px",
        },
        {
          text: this.$i18n.t("TXT_MARKETPLACE"),
          align: "center",
          sortable: true,
          value: "crawlerName",
          width: "200px",
        },
        {
          text: this.$i18n.t("TXT_SELLER"),
          align: "center",
          sortable: true,
          value: "sellerName",
          width: "180px",
        },
        {
          text: this.$i18n.t("TXT_PRICE"),
          align: "center",
          sortable: true,
          value: "price",
          width: "120px",
        },
        {
          text: this.$i18n.t("TXT_CITY_STATE"),
          align: "center",
          sortable: true,
          value: "city",
          width: "150px",
        },
        {
          text: this.$i18n.t("TXT_COLECT_DATE"),
          align: "center",
          sortable: true,
          value: "refDate",
          width: "180px",
        },
      ],
      products: [],

      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },
    };
  },

  computed: {},

  watch: {
    modalOpen: {
      handler(value) {
        if (value) this.getProducts();
      },
      immediate: true,
    },
  },
  methods: {
    formatMonetary,
    formatDate,
    async getProducts() {
      this.isLoading = true;
      this.products = [];

      try {
        let response = await Service.getMarketResultItem(
          this.idEnterprisePricesProjection
        );
        this.products = response?.data?.result ?? [];
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_FAIL_SEARCH_PRODUCT"),
          error: "error",
        });
      }

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-title {
  text-align: center;
  background-color: #f5f5f5;
  border-radius: 5px;
}
</style>
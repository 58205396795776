<template>
  <v-container fluid>
    <titleBreadCrumbsComponent
      :breadCrumbs="breadCrumbs"
      :title="$t('TXT_DINAMIC_PRICING_RULE').toUpperCase()"
      :hasTooltip="false"
    />
    <Rule-main-card />
  </v-container>
</template>

<script>
import titleBreadCrumbsComponent from "@/Design_System/common/title.vue";
import RuleMainCard from "./components/handler-group-rules/handler-group-rules.vue";

export default {
  name: "pricing-rules",
  components: { titleBreadCrumbsComponent, RuleMainCard },
  data() {
    return {
      breadCrumbs: [
        {
          text: this.$i18n.t("TXT_DASHBOARD_PRICING"),
        },
        {
          text: "Predimonitor",
        },
      ],
    };
  },

  computed: {
    copilotEnv() {
      return process.env.VUE_APP_ENABLE_COPILOT;
    },
    enableCopilot() {
      return this.copilotEnv == "true";
    },
  },

  created() {
    if (this.enableCopilot) this.$store.dispatch("INITIALIZE_SOCKET");
  },
};
</script> 
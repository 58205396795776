<template>
  <div v-if="showClusters" class="d-flex align-center">
    <Skeleton-loader v-if="isLoading" />

    <div
      v-else
      v-for="(cluster, index) in clusters"
      :key="index"
      class="d-flex align-center"
    >
      <Cluster-data
        :title="cluster.title"
        :value="cluster.value"
        :margin="cluster.margin"
      />
    </div>
  </div>
</template>

<script>
import ClusterData from "./components/cluster-data.vue";
import service from "@/service/pricer/product-list.js";
import SkeletonLoader from "./components/skeleton-loader.vue";
const Service = new service();
export default {
  components: { ClusterData, SkeletonLoader },
  props: {
    idEnterprisePricesProjection: {
      type: Number,
    },
  },

  data: () => ({
    isLoading: false,
    clusters: [
      {
        title: "Ouro",
        value: null,
        margin: null,
        key: "discountPrice1",
      },
      {
        title: "Prata",
        value: null,
        margin: null,
        key: "discountPrice2",
      },
      {
        title: "Bronze",
        value: null,
        margin: null,
        key: "discountPrice3",
      },
    ],
  }),

  computed: {
    showClusters() {
      if (this.isLoading) return true;

      return this.clusters.every((cluster) => {
        return (
          cluster.value !== null &&
          cluster.value !== "-" &&
          cluster.margin !== null &&
          cluster.margin !== "-"
        );
      });
    },
  },

  methods: {
    async getClusters() {
      this.isLoading = true;

      try {
        let response = await Service.getBoldClusters(
          this.idEnterprisePricesProjection
        );
        this.formatData(response?.data?.result ?? null);
      } catch (error) {
        this.$handleError(error, "Erro ao buscar clusters");
      }

      this.isLoading = false;
    },

    formatData(response) {
      if (!response) {
        return;
      }

      this.clusters.forEach((cluster) => {
        cluster.value = response[cluster.key];
        cluster.margin =
          response[`discountPriceMargin${cluster.key.slice(-1)}`];
      });
    },
  },

  created() {
    this.getClusters();
  },
};
</script>
<template>
  <v-card class="pa-4">
    <v-row no-gutters align="end">
      <v-col cols="3" class="mr-6">
        <Prd-date-picker
          :modelValue="selectedData"
          :externalDisabled="isLoading"
          @input="selectedData = $event"
        />
      </v-col>
      <v-col cols="4">
        <Saved-filters
          v-model="savedFilter"
          ref="handlerSavedFilters"
          :externalDisabled="isLoading"
          @change="savedFilter = $event"
        />
      </v-col>
    </v-row>

    <h4 class="my-6">{{ $t("TXT_FILTERS") }}</h4>

    <div class="filter-label">
      <span>{{ this.$i18n.t("TXT_PRODUCT_FILTERS") }}</span>
    </div>

    <Default-filters
      :ref="refs[0]"
      :externalLoading="isLoading"
      :savedFilter="savedFilter"
      @handleChange="getFilters"
      @toogleUnlockSeeResults="toogleUnlockSeeResults"
      class="mb-4"
    />

    <Machinery-filters
      v-if="hasMachineryResource"
      :ref="refs[1]"
      :externalLoading="isLoading"
      :savedFilter="savedFilter"
      @handleChange="getFilters"
      @toogleUnlockSeeResults="toogleUnlockSeeResults"
      class="mb-4"
    />

    <v-row no-gutters justify="space-between" class="my-6">
      <v-col cols="auto">
        <Delete-filters-modal
          v-if="savedFilter"
          :savedFilter="savedFilter"
          @cleanFilters="cleanFilters"
          @reorganizeSavedFilters="$refs.handlerSavedFilters.reorganize($event)"
        />
      </v-col>

      <v-col cols="auto">
        <v-row no-gutters justify="end">
          <Prd-depressed-button
            v-if="showCleanButton"
            :title="$t('TXT_CLEAR_FILTERS')"
            :leftIcon="'mdi-close'"
            :class="cleanButtonClassess"
            @click="cleanFilters"
          />
          <Save-filters-modal
            v-if="hasSelectedFilters"
            :selections="selections"
            @addNewSavedFilter="addNewSavedFilter"
          />
        </v-row>
      </v-col>
    </v-row>

    <v-row no-gutters justify="end">
      <Prd-btn
        :title="$t('TXT_SEE_RESULTS')"
        :disabled="!hasSelectedFilters || isLoading || areThereSelections"
        @click="getResults"
      />
    </v-row>
  </v-card>
</template>

<script>
import PrdDatePicker from "@/components/common/prd-date-picker.vue";
import SavedFilters from "./components/saved-filters.vue";
import DefaultFilters from "./components/default-filters.vue";
import MachineryFilters from "./components/machinery-filters.vue";
import SaveFiltersModal from "./components/save-filters-modal.vue";
import DeleteFiltersModal from "./components/delete-filters-modal.vue";
import PrdDepressedButton from "@/components/common/prd-depressed-button.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import FiltersPredimonitorService from "@/service/predimonitor/filters-predimonitor-service-v2";
export default {
  components: {
    PrdDatePicker,
    SavedFilters,
    DefaultFilters,
    MachineryFilters,
    SaveFiltersModal,
    DeleteFiltersModal,
    PrdDepressedButton,
    PrdBtn,
  },
  data() {
    return {
      selectedData: [],
      savedFilter: null,
      refs: ["defaultFilters", "machineryFilters"],
      isLoading: false,
      service: new FiltersPredimonitorService(),
      timeoutId: null,
      allProducts: [],
      areThereSelections: false,
    };
  },
  computed: {
    selections() {
      return this.getSelections();
    },
    hasSelectedFilters() {
      const hasArrayItem = Object.entries(this.selections).some(
        ([key, value]) =>
          key !== "date" && Array.isArray(value) && value.length > 0
      );

      const hasValidObjectProperty = Object.entries(this.selections).some(
        ([key, value]) =>
          key !== "date" &&
          value &&
          typeof value === "object" &&
          Object.values(value).some((prop) => prop !== null && prop !== "")
      );

      return hasArrayItem || hasValidObjectProperty;
    },
    hasMachineryResource() {
      return this.hasResource("marketplace.machinery");
    },
    showCleanButton() {
      return this.hasSelectedFilters || this.savedFilter != null;
    },
    cleanButtonClassess() {
      return this.hasSelectedFilters ? "mr-4" : "";
    },
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    isCost() {
      return this.$route.query.type?.includes("cost") ?? false;
    },
  },
  watch: {
    savedFilter: {
      handler(value) {
        if (value)
          setTimeout(() => {
            this.getFilters();
          }, 350);
      },
    },
    isCost: {
      handler() {
        this.cleanFilters();
      },
    },
  },
  methods: {
    addNewSavedFilter(filter) {
      this.$refs.handlerSavedFilters.addNewSavedFilter(filter);
      this.savedFilter = filter
    },
    toogleUnlockSeeResults() {
      this.areThereSelections = !this.areThereSelections;
    },
    hasResource(value) {
      return this.$store.getters.hasResource(value);
    },

    getBeforeSevenDays() {
      this.selectedData = [];
      const currentDate = new Date();
      let beforeSevenDays = new Date();

      beforeSevenDays.setDate(currentDate.getDate() - 7);

      const formatDate = (date) => date.toISOString().split("T")[0];
      this.selectedData = [
        formatDate(beforeSevenDays),
        formatDate(currentDate),
      ];
    },

    cleanFilters() {
      this.getBeforeSevenDays();
      this.savedFilter = null;
      this.refs.forEach((ref) => {
        if (this.$refs[ref]) this.$refs[ref].cleanSelection();
      });
      this.getFilters();
    },

    getSelections() {
      let selections = {};

      selections.date = {
        start: this.selectedData[0],
        end: this.selectedData[1],
      };

      this.refs.forEach((ref) => {
        if (this.$refs[ref])
          selections = {
            ...selections,
            ...this.$refs[ref].getSelections(),
          };
      });

      return selections;
    },

    async getFilters() {
      this.isLoading = true;
      const request = this.prepareRequestForGetFilters();
      try {
        const response = await this.service.getFilters(this.idCompany, request);
        this.populateFilters(response?.data ?? null);
        this.setAllProducts(request, response.data.products);
      } finally {
        this.isLoading = false;
      }
    },

    prepareRequestForGetFilters() {
      let request = {
        productNames: [],
        monitoringItemIds: [],
        categories: [],
        sellers: [],
        brands: [],
        origins: [],
        states: [],
        years: [],
        isCost: this.isCost,
      };

      const keys = Object.keys(request);
      keys.forEach((key) => {
        if (key == "isCost") return;
        if (key == "monitoringItemIds")
          request.monitoringItemIds =
            this.selections?.products?.map(
              (product) => product.idMonitoringItem
            ) ?? [];
        else request[key] = this.selections[key] ?? [];
      });

      return request;
    },

    populateFilters(items) {
      if (!items) return;
      this.refs.forEach((ref) => {
        if (this.$refs[ref]) {
          this.$refs[ref].populateFilters(items);
          this.$refs[ref].removeNonExistentSelectedItem(items);
        }
      });
    },

    setAllProducts(request, products) {
      if (!request) return;

      const keys = Object.keys(request);
      const isEmptyRequest = keys.every(
        (key) => Array.isArray(request[key]) && request[key].length == 0
      );

      if (isEmptyRequest && this.allProducts.length == 0)
        this.allProducts = products;

      const isOnlyProductsSelected =
        request.monitoringItemIds.length > 0 &&
        keys
          .filter((key) => key !== "monitoringItemIds")
          .every((key) => request[key].length == 0);

      if (isOnlyProductsSelected && this.$refs[this.refs[0]])
        this.$refs[this.refs[0]].populateAllProducts(this.allProducts);
    },

    getResults() {
      const getJsonDate = (date) => new Date(date).toISOString();

      const request = {
        productNames: [],
        monitoringItemIds:
          this.selections.products.map((product) => product.idMonitoringItem) ??
          [],
        categories: this.selections.categories ?? [],
        sellers: this.selections.sellers ?? [],
        brands: this.selections.brands ?? [],
        origins: this.selections.origins ?? [],
        states: this.selections.states ?? [],
        years: this.selections.years ?? [],
        startDate: getJsonDate(this.selections.date.start),
        endDate: getJsonDate(this.selections.date.end),
        hourMeter: {
          min: this.selections?.hourMeter?.min ?? 0,
          max: this.selections?.hourMeter?.max ?? 0,
        },
        isCost: this.isCost,
        companyId: this.idCompany,
      };

      this.$emit("startSearchActions", request);

      this.$store.dispatch("getResults", request);
    },
  },
  mounted() {
    this.getBeforeSevenDays();
    this.getFilters();
  },
};
</script>

<style lang="scss" scoped>
.filter-label {
  padding: 4px 0px;
  margin-bottom: 16px;
  border-bottom: 0.5px solid #ccc;
  box-sizing: border-box;
  font-family: "Ubuntu", "Arial", "sans-serif";
  font-weight: bold !important;
  font-size: 14px !important;
}

.filter-label span {
  width: max-content;
  border-bottom: 3px solid $brand-color-secondary-pure;
  padding-bottom: 4px;
}
</style>

import service from "@/service/company-config/resource-group-management.js";
const Service = new service();

export default {
    state: {
        groupLoading: false,
        groups: [],
        resources: [],
    },

    mutations: {
        setResources(state, data) {
            state.resources = data;
        },
        setGroups(state, data) {
            state.groups = data;
        },
        setGroupLoading(state, data) {
            state.groupLoading = data
        }
    },
    actions: {
        async GET_GROUPS({  commit }, idCompany) {

            commit('setGroupLoading', true);

            try {
                const response = await Service.getGroups(idCompany)

                commit('setGroups', response?.data?.answer ?? []);
            } catch (error) {
                console.error(error);
            }

            commit('setGroupLoading', false);

        },

        async GET_RESOURCES({  commit }, idCompany) {
            try {
                const response = await Service.getResources(idCompany)

                response?.data?.answer.forEach((el) => {
                    el.select = false
                }) ?? []

                commit('setResources', response?.data?.answer ?? []);
            } catch (error) {
                console.error(error);
            }
        },
    },


}



<template>
  <div>
    <v-expansion-panel class="mb-3" :disabled="isLoading">
      <Panel-header
        :item="groupRules"
        :index="index"
        @updateIsLoading="isLoading = $event"
      />
      <Panel-content
        :item="groupRules"
        :idRule="item.idRule"
        :itsOpen="itsOpen"
        @closePanel="$emit('closePanel')"
      />
    </v-expansion-panel>
  </div>
</template>

<script>
import PanelContent from "./components/panel-content/panel-content.vue";
import PanelHeader from "./components/panel-header/panel-header.vue";
import GroupRules from "../../../../../../utils/GroupRules";
export default {
  components: { PanelHeader, PanelContent },
  props: {
    item: {
      type: Object,
      default: () => ({
        idRule: null,
        isSimulation: false,
        description: null,
        isActive: false,
        numberOrder: null,
      }),
    },
    index: {
      type: Number,
      default: null,
    },
    itsOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    groupRules() {
      return this.$store.getters.getGroupRuleByIdRule(this.item.idRule);
    },
  },
  watch: {
    item: {
      handler(value) {
        this.createGroupRules(value);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    createGroupRules(groupRule) {
      const groupRules = new GroupRules(
        groupRule.idRule,
        groupRule.description,
        groupRule.isActive,
        groupRule.numberOrder
      );
      this.$store.commit("insertGroupRules", groupRules);
    },
  },
};
</script>

<style>
</style>
<template>
  <div style="display: flex; gap: 1rem" v-if="oportunitiesData != null">
    <v-card style="width: 50%" class="pa-4">
      <v-row no-gutters align="center">
        <h4>{{ $t("TXT_INCREASED_PROFIT").toUpperCase() }}</h4>
        <!-- <tooltip-informations :title="''" :rightIcon="true" /> -->
        <v-spacer></v-spacer>
        <!-- <v-icon :color="$prdStyles('color-primary')">
          mdi-arrow-right-bold-box-outline
        </v-icon> -->
      </v-row>

      <div class="my-flex mt-4">
        <div>
          <label>{{ $t("TXT_TOTAL_OF_ITEMS") }}</label>
          <h4 class="number-destaq">{{ profitItems }}</h4>
        </div>

        <div>
          <label>{{ $t("TXT_PROFIT_CURRENT_PRICE") }}</label>
          <h4 class="number-destaq">
            {{ formatMonetary(profitActualPrice) }}
          </h4>
        </div>

        <div>
          <label>{{ $t("TXT_PROFIT_FROM_AI_ADOPTION") }}</label>
          <h4 class="number-destaq-green">
            {{ formatMonetary(profitItemsValue) }}
          </h4>
        </div>
      </div>
    </v-card>

    <v-card style="width: 50%" class="pa-4">
      <v-row no-gutters align="center">
        <h4>{{ $t("TXT_MARGIN_INCREASE") }}</h4>
        <!-- <tooltip-informations :title="''" :rightIcon="true" /> -->
        <v-spacer></v-spacer>
        <!-- <v-icon :color="$prdStyles('color-primary')">
          mdi-arrow-right-bold-box-outline
        </v-icon> -->
      </v-row>

      <div class="my-flex mt-4">
        <div>
          <label>{{ $t("TXT_TOTAL_OF_ITEMS") }}</label>
          <h4 class="number-destaq">{{ marginItems }}</h4>
        </div>

        <div>
          <label>{{ $t("TXT_MARGIIN_CURRENT_PRICE") }}</label>
          <h4 class="number-destaq">
            {{
              marginActualPrice
                ? marginActualPrice.toString().replace(".", ",")
                : ""
            }}%
          </h4>
        </div>

        <div>
          <label>{{ $t("TXT_MARGIN_FROM_AI_ADOPTION") }}</label>
          <h4 class="number-destaq-green">
            {{
              marginItemsValue
                ? marginItemsValue.toString().replace(".", ",")
                : ""
            }}%
          </h4>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { formatMonetary } from "@/utils/format-toMonetary";

// import tooltipInformations from "@/Design_System/common/tooltip-informations.vue";

export default {
  props: ["oportunitiesData"],
  components: {
    /*tooltipInformations*/
  },
  data() {
    return {};
  },

  computed: {
    profitItems() {
      return this.oportunitiesData?.increaseProfit?.totalItems ?? null;
    },
    profitActualPrice() {
      return this.oportunitiesData?.increaseProfit?.actualPriceProfit ?? null;
    },
    profitItemsValue() {
      return this.oportunitiesData?.increaseProfit?.totalItemsProfit ?? null;
    },

    marginItems() {
      return this.oportunitiesData?.increaseMargin?.totalItems ?? null;
    },
    marginActualPrice() {
      return this.oportunitiesData?.increaseMargin?.actualPriceProfit ?? null;
    },
    marginItemsValue() {
      return this.oportunitiesData?.increaseMargin?.totalItemsProfit ?? null;
    },
  },

  methods: {
    formatMonetary,
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  justify-content: space-between;
}

.number-destaq {
  color: $brand-color-primary-pure;
  font-size: 20;
}

.number-destaq-green {
  color: $feedback-color-success-pure;
}
</style>
import Vue from "vue";
import VueRouter from "vue-router";


//loginArea
import loginArea from "@/views/login/login";
import signIn from "@/views/login/sign-in.vue";
import signOut from "@/views/login/sign-out";
import passwordRecovery from "@/views/login/password-recovery-v2/password-recovery-main.vue";
import uplSSO from "@/views/login/upl-sso.vue"
//mainArea
import main from "@/views/main/main";

//CompanySelect
import companySelect from "@/views/main/company-select/company-select-main.vue";
import createCompany from "@/views/main/company-select/components/create-company.vue";

//home
import home from "@/views/main/home/home-container";

//pricing
import pricer from "@/views/main/pricer/pricing-main.vue"

//redirect
import redirect from "@/views/redirect";

//PrediMonitor
import prediMonitor from "@/views/main/predi-monitor/predi-monitor";

//Predimonitor V2
import predimonitorV2 from "@/views/main/predimonitor-V2/predimonitor-V2-main.vue";


// Predifut
import predifutMain from "@/views/main/predifut/predifut-main";
import predifutSelectGame from "@/views/main/predifut/predifut-select-game";
import predifutSimulateMatch from "@/views/main/predifut/predifut-simulate-match";


// Tags config
import tagsConfig from "@/views/main/tags-config/tags-config-main.vue";


// UPL
import uplMain from '@/views/main/upl/upl-main.vue'
import inputMain from '@/views/main/upl/components/inputs/V2/new-edit-products-main.vue'

// Deliberation
import deliberationMain from "@/views/main/deliberation/deliberation-main.vue";

// Crawler Config
import crawlerConfig from "@/views/main/crawlers/crawler-config/crawler-config-main.vue";
// Crawlers Management
import crawlerManagement from "@/views/main/crawlers/crawler-management/crawler-management-main.vue";
// Crawler execute
import crawlerExecute from "@/views/main/crawlers/crawler-execute/crawler-execute-main.vue";

//Mix Products Apriori
import mixProductsApriori from "@/views/main/mix-products-apriori/mix-products-apriori-main.vue";

//New ai pricing
import newAiPricing from "@/views/main/new-pricing/new-pricing-main";

// new Payment page
import paymentHistoryMain from "@/views/main/payment/payment-history/payment-history-main.vue";

//Faqs
import faqsMain from "@/views/main/faqs/faqs-main.vue";
import supportMain from "@/views/main/support/support-main.vue";

//Profile Edit
import profileMain from "@/views/main/profile/profile-main.vue";

// Admin Routes
import administrativeAccessMain from "@/views/main/administrative-access/administrative-access-main.vue";

// User management
import companyConfig from "@/views/main/company-configs/company-config-main.vue";

// pricing rules
import pricingRules from "@/views/main/pricing-rules/pricing-rules-main.vue"
import createRule from "@/views/main/pricing-rules/components/handler-group-rules/components/create-rule-main.vue"
import generalSystemConfigsMain from "@/views/main/general-system-configs/general-system-configs-main.vue";

import Hotjar from '@hotjar/browser';
import store from '@/store';
import ChurnAnalysisMain from "@/views/main/churn/churn-analysis-main.vue";
const userData = store.getters.userData
const predifyAdmin = store.getters.isPredifyAdmin
const resources = store.getters.resources
const roles = store.getters.adminRoles
Vue.use(VueRouter);

const routes = [
  {
    path: "" || "/",
    redirect: { name: "sign-in" },
  },
  {
    path: "/login",
    component: loginArea,
    children: [
      {
        path: "" || "/",
        redirect: { name: "sign-in" },
      },
      {
        path: "sign-in",
        name: "sign-in",
        component: signIn,
        meta: {
          title: "PREDIFY | SIGN-IN",
        },
      },
      {
        path: "password-recovery",
        name: "password-recovery",
        component: passwordRecovery,
        meta: {
          title: "PREDIFY | RECUPERAR SENHA",
        },
      },
      {
        path: "uplsso",
        name: "upl-sso",
        component: uplSSO,
      },

      {
        path: "sign-out",
        name: "sign-out",
        component: signOut,
        meta: {
          title: "PREDIFY | SIGN-OUT",
        },
      },
    ],
  },
  {
    path: "/main",
    component: main,
    children: [
      {
        path: "" || "/",
        redirect: { name: "home-container" },
      },
      {
        path: "company-select",
        name: "company-select",
        component: companySelect,
        meta: {
          title: "PREDIFY | SELEÇÃO DE EMPRESAS",
        },
      },
      {
        path: "create-company",
        name: "create-company",
        component: createCompany,
        meta: {
          title: "PREDIFY | CRIAR EMPRESA",
          predifyAdmin: true
        },
      },
      {
        path: "home",
        name: "home-container",
        component: home,
        meta: {
          title: "PREDIFY | HOME",
          companyRequired: true,
          hideToCompany: [3116],
          hideToResource: ["predimonitor.only"],
        },
      },
      {
        path: "predifut",
        name: "predifut",
        component: predifutMain,
        meta: {
          title: "PREDIFY | PREDIFUT",
          showToCompany: [2454],
          showToResource: ["enterprise.predfut"],
          hideToResource: ["predimonitor.only"],
        },
        children: [
          {
            path: "select-game",
            name: "select-game",
            component: predifutSelectGame,
          },
          {
            path: "simulate/:idEnterpriseEventHistory",
            name: "simulate",
            component: predifutSimulateMatch,
          },
        ],
      },
      {
        path: "pricer",
        name: "pricer-main",
        component: pricer,
        meta: {
          title: "PREDIFY | PRICER",
          companyRequired: true,
          hideToCompany: [2806, 2652],
          hideToResource: ['predimonitor.v2'],
          hideToRoles: ['2002']
        }
      },
      {
        path: "ai-pricing-fujioka",
        name: "new-ai-pricing",
        component: newAiPricing,
        meta: {
          title: "PREDIFY | PRICING",
          showToCompany: [2806, 2652, 1106]
        },
      },
      {
        path: "predimonitor",
        name: "predimonitor",
        component: prediMonitor,
        meta: {
          companyRequired: true,
          showToResource: ['predimonitor', 'client.bobs', 'enterprise.config1.menu'],
          hideToResource: ['predimonitor.v2']
        }
      },
      {
        path: "predimonitor-V2",
        name: "predimonitor-V2",
        component: predimonitorV2,
        meta: {
          title: "PREDIFY | PREDIMONITOR",
          companyRequired: true,
          showToResource: ['predimonitor.v2'],
        },
      },
      {
        path: "tags-config",
        name: "tags-config",
        component: tagsConfig,
        meta: {
          title: "PREDIFY | CONFIGURAÇÃO DE TAGS",
          companyRequired: true,
        },
      },
      {
        path: "upl",
        name: "upl",
        component: uplMain,
        meta: {
          title: "PREDIFY | ONE PAGE",
          showToCompany: [2858]
        },
      },
      {
        path: "inputs-main",
        name: "inputs-main",
        component: inputMain,
        meta: {
          title: "PREDIFY | UPL",
          showToCompany: [2858]
        },
      },
      {
        path: "deliberation-main",
        name: "deliberation-main",
        component: deliberationMain,
        meta: {
          title: "PREDIFY | DELIBERAÇÃO",
          showToCompany: [2858]
        },
      },
      {
        path: "pricing-rules",
        name: "pricing-rules",
        component: pricingRules,
        meta: {
          title: 'PREDIFY | REGRAS DE PRECIFICAÇÃO',
          companyRequired: true
        }
      },
      {
        path: "create-pricing-rule/:idRule?",
        name: "create-pricing-rule",
        component: createRule,
        meta: {
          title: 'PREDIFY | CRIAR REGRAS',
          companyRequired: true
        }
      },
      {
        path: "company-config",
        name: "company-config-main",
        component: companyConfig,
        meta: {
          title: "PREDIFY | CONFIGURAÇÕES DA EMPRESA",
          showToRoles: true,
          hideToRoles: [2001, 2002, 2003]
        },
      },

      {
        path: "crawler-management",
        name: "crawler-management",
        component: crawlerManagement,
        meta: {
          title: "PREDIFY | CRAWLERS",
          predifyAdmin: true,
        },
      },
      {
        path: "crawler-config",
        name: "crawler-config",
        component: crawlerConfig,
        meta: {
          title: "PREDIFY | CONFIGURAÇÃO DE CRAWLERS",
          predifyAdmin: true,
        },
      },
      {
        path: "crawler-execute",
        name: "crawler-execute-main",
        component: crawlerExecute,
        meta: {
          title: "PREDIFY | EXECUTAR CRAWLER MANUALMENTE",
          showToResource: ['predimonitor.v2'],
          companyRequired: true
        },
      },
      {
        path: "mix-products-apriori",
        name: "mix-products-apriori",
        component: mixProductsApriori,
        meta: {
          title: "PREDIFY | MIX PRODUTOS APRIORI",
          predifyAdmin: true,
        },
      },
      {
        path: "faqs",
        name: "faqs",
        component: faqsMain,
        meta: {
          title: "PREDIFY | FAQS",
          companyRequired: true
        },
      },
      {
        path: "support",
        name: "support-main",
        component: supportMain,
        meta: {
          title: "PREDIFY | SUPORTE",
        },
      },
      {
        path: "usuario/perfil",
        name: "profileMain",
        component: profileMain,
        meta: {
          title: "PREDIFY | PERFIL",
        },
      },
      {
        path: "pagamento/historico",
        name: "paymentHistory",
        component: paymentHistoryMain,
        meta: {
          title: "PREDIFY | HISTÓRICO DE PAGEMENTOS",
          companyRequired: true
        },
      },
      {
        path: "administracao/administracao",
        name: "administrativeAccess",
        component: administrativeAccessMain,
        meta: {
          title: "PREDIFY | ACESSO ADMINISTRATIVO",
          predifyAdmin: true
        },
      },
      {
        path: "empresa/admin-configuracoes",
        name: "generalSystemConfigsMain",
        component: generalSystemConfigsMain,
        meta: {
          title: "PREDIFY | CONFIGURAÇÕES GERAIS DO SISTEMA",
          predifyAdmin: true
        },
      },
      {
        path: "churn-analysis",
        name: "churn-analysis",
        component: ChurnAnalysisMain,
        meta: {
          title: 'PREDIFY | ANÁLISE DE CHURN',
          companyRequired: true
        }
      },
    ],
  },
  {
    path: "/redirect",
    component: redirect,
    name: "redirect",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function companyRequired(isRequired) {
  if (isRequired && !userData.idCompany) return false;
  else return true;
}

function adminPredifyOnly(isRequired) {
  if (!isRequired) return true;

  if (isRequired && predifyAdmin) return true;
  else return false;
}

function showToCompany(companys) {
  if (!companys || companys.length <= 0) return true;

  if (companys.includes(userData.idCompany)) return true;
  else return false;
}

function hideToCompany(companys) {
  if (!companys || companys.length <= 0) return true;

  if (companys.includes(userData.idCompany)) return false;
  else return true;
}

function showToResource(resourcesData) {
  if (!resourcesData || resourcesData.length <= 0) return true;

  let foundedResource = resourcesData.some((item) =>
    resources.includes(item)
  );

  if (foundedResource) return true;
  else return false;
}

function hideToResource(resourcesData) {
  if (!resourcesData || resourcesData.length <= 0) return true;

  let foundedResource = resourcesData.some((item) =>
    resources.includes(item)
  );

  if (foundedResource) return false;
  else return true;
}

function showToRoles(rolesData) {
  if (!rolesData) return true;

  if (rolesData && roles.length > 0) return true
  return false
}

function hideToRoles(rolesData) {
  if (!rolesData || rolesData.length <= 0) return true;

  let foundedResource = rolesData.some((item) =>
    roles.includes(item)
  );

  if (foundedResource) return false;
  else return true;
}

function verifyPredimonitorOnly() {
  return resources.find((el) => el == 'predimonitor.only')
}

router.beforeEach((to, from, next) => {
  if (from.path.includes('/main') && to.path.includes('/login/sign-out')) {
    next()
    return
  }

  if (to.path.includes('/main') && !userData.token) {
    next({
      path: '/login',
    });
    return
  } else if (to.path.includes('/login') && userData.token) {
    next({
      path: '/main'
    })
    return
  }


  if (companyRequired(to.meta.companyRequired) && adminPredifyOnly(to.meta.predifyAdmin) && showToCompany(to.meta.showToCompany) && hideToCompany(to.meta.hideToCompany) && showToResource(to.meta.showToResource) && hideToResource(to.meta.hideToResource) && showToRoles(to.meta.showToRoles) && hideToRoles(to.meta.hideToRoles)) {
    next()
  } else if (verifyPredimonitorOnly()) {
    next({
      path: '/main/predimonitor?type=competitor',
    });
    return
  } else {
    next({
      path: '/main/company-select',
    });
    return
  }
  document.title = `${to?.meta?.title ?? "PREDIFY"}`;
});

router.afterEach((to) => {
  if (process.env.NODE_ENV != 'development')
    Hotjar.stateChange(to.fullPath)
});

export default router;

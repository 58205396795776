import axiosInstance, { API_URL_UPL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class UPLService extends ServiceBase {

  async gettGraficoHistoricoDesempenho() {
    return await axiosInstance.get(
      API_URL_UPL + "api/GraficoHistoricoDesempenho"
    );
  }

  async getGraficoHistoricoDesempenhoTemplate() {
    return await axiosInstance.get(
      API_URL_UPL + "api/GraficoHistoricoDesempenho/Template",
      { responseType: "blob" }
    );
  }

  async gettGraficoCustoParaPrecificacao() {
    return await axiosInstance.get(
      API_URL_UPL + "api/GraficoCustoParaPrecificacao"
    );
  }

  async getGraficoCustoParaPrecificacaoTemplate() {
    return await axiosInstance.get(
      API_URL_UPL + "api/GraficoCustoParaPrecificacao/Template",
      { responseType: "blob" }
    );
  }

  async gettGraficoAtingimentoFinReview() {
    return await axiosInstance.get(
      API_URL_UPL + "api/GraficoAtingimentoFinReview"
    );
  }

  async getGraficoAtingimentoFinReviewTemplate() {
    return await axiosInstance.get(
      API_URL_UPL + "api/GraficoAtingimentoFinReview/Template",
      { responseType: "blob" }
    );
  }

  async gettCustoReposicaoCompletoFiles() {
    return await axiosInstance.get(
      API_URL_UPL + "api/CustoReposicaoCompletoFiles"
    );
  }

  async getCustoReposicaoCompletoFilesTemplate() {
    return await axiosInstance.get(
      API_URL_UPL + "api/CustoReposicaoCompletoFiles/Template",
      { responseType: "blob" }
    );
  }

  async getBaseExtractsTemplate() {
    return await axiosInstance.get(API_URL_UPL + "api/BaseExtracts/Template", {
      responseType: "blob",
    });
  }

  async getBaseExtracts() {
    return await axiosInstance.get(API_URL_UPL + "api/BaseExtracts");
  }

  async getInternalTemplate() {
    return await axiosInstance.get(API_URL_UPL + "api/InternalFiles/Template", {
      responseType: "blob",
    });
  }

  async getCustoReposicaoTemplate() {
    return await axiosInstance.get(
      API_URL_UPL + "api/CustoReposicaoFiles/Template",
      { responseType: "blob" }
    );
  }

  async getVariaveisTributacao() {
    return await axiosInstance.get(API_URL_UPL + "api/VariaveisTributacao/");
  }

  async getGraficoAtingimentoFinReview(productName) {
    return await axiosInstance.get(
      API_URL_UPL + `api/GraficoAtingimentoFinReview/${productName}`
    );
  }

  async getFilters(statusPortfolio) {
    return await axiosInstance.get(API_URL_UPL + `api/Deliberation/Filters${statusPortfolio ? `?statusPortfolio=${statusPortfolio}` : ''}`);
  }

  async getDeliberationVersusBudget(
    deliberationRefId,
    brand,
    segment,
    precificationType,
    statusPortfolio
  ) {
    return await axiosInstance.get(
      API_URL_UPL +
      `api/Deliberation/VersusBudget?deliberationRefId=${deliberationRefId}&brand=${brand}&segment=${segment}&precificationType=${precificationType}&statusPortfolio=${statusPortfolio}`
    );
  }

  async getDeliberationVersusFinReview(
    deliberationRefId,
    brand,
    segment,
    precificationType,
    statusPortfolio
  ) {
    return await axiosInstance.get(
      API_URL_UPL +
      `api/Deliberation/VersusFin?deliberationRefId=${deliberationRefId}&brand=${brand}&segment=${segment}&precificationType=${precificationType}&statusPortfolio=${statusPortfolio}`
    );
  }

  async getDeliberationProjecoesCogs(
    deliberationRefId,
    brand,
    segment,
    precificationType,
    statusPortfolio
  ) {
    return await axiosInstance.get(
      API_URL_UPL +
      `api/Deliberation/ProjecoesCogs?deliberationRefId=${deliberationRefId}&brand=${brand}&segment=${segment}&precificationType=${precificationType}&statusPortfolio=${statusPortfolio}`
    );
  }

  async getDeliberationProjecoesNetPrice(
    deliberationRefId,
    brand,
    segment,
    precificationType,
    statusPortfolio
  ) {
    return await axiosInstance.get(
      API_URL_UPL +
      `api/Deliberation/ProjecoesNetPrice?deliberationRefId=${deliberationRefId}&brand=${brand}&segment=${segment}&precificationType=${precificationType}&statusPortfolio=${statusPortfolio}`
    );
  }

  async getDeliberationProjecoesGrossMarginInMio(
    deliberationRefId,
    brand,
    segment,
    precificationType,
    statusPortfolio
  ) {
    return await axiosInstance.get(
      API_URL_UPL +
      `api/Deliberation/ProjecoesGrossMarginInMio?deliberationRefId=${deliberationRefId}&brand=${brand}&segment=${segment}&precificationType=${precificationType}&statusPortfolio=${statusPortfolio}`
    );
  }

  async getDeliberationProjecoesVolumeInTons(
    deliberationRefId,
    brand,
    segment,
    precificationType,
    statusPortfolio
  ) {
    return await axiosInstance.get(
      API_URL_UPL +
      `api/Deliberation/ProjecoesNetSalesInMio?deliberationRefId=${deliberationRefId}&brand=${brand}&segment=${segment}&precificationType=${precificationType}&statusPortfolio=${statusPortfolio}`
    );
  }

  async getDeliberationProjecoesNetSalesInMio(
    deliberationRefId,
    brand,
    segment,
    precificationType,
    statusPortfolio
  ) {
    return await axiosInstance.get(
      API_URL_UPL +
      `api/Deliberation/ProjecoesVolumeInTons?deliberationRefId=${deliberationRefId}&brand=${brand}&segment=${segment}&precificationType=${precificationType}&statusPortfolio=${statusPortfolio}`
    );
  }

  async getDeliberationInfo(
    deliberationRefId,
    brand,
    segment,
    precificationType,
    statusPortfolio
  ) {
    return await axiosInstance.get(
      API_URL_UPL +
      `api/Deliberation/info?deliberationRefId=${deliberationRefId}&brand=${brand}&segment=${segment}&precificationType=${precificationType}&statusPortfolio=${statusPortfolio}`
    );
  }

  async getDeliberationPriceListFVUS(
    deliberationRefId,
    brand,
    segment,
    precificationType,
    statusPortfolio
  ) {
    return await axiosInstance.get(
      API_URL_UPL +
      `api/Deliberation/PriceListFVUS?deliberationRefId=${deliberationRefId}&brand=${brand}&segment=${segment}&precificationType=${precificationType}&statusPortfolio=${statusPortfolio}`
    );
  }

  async getDeliberationPriceListFVBRL(
    deliberationRefId,
    brand,
    segment,
    precificationType,
    statusPortfolio
  ) {
    return await axiosInstance.get(
      API_URL_UPL +
      `api/Deliberation/PriceListFVBRL?deliberationRefId=${deliberationRefId}&brand=${brand}&segment=${segment}&precificationType=${precificationType}&statusPortfolio=${statusPortfolio}`
    );
  }

  async getDeliberationPriceListPVBRL(
    deliberationRefId,
    brand,
    segment,
    precificationType,
    statusPortfolio
  ) {
    return await axiosInstance.get(
      API_URL_UPL +
      `api/Deliberation/PriceListPVBRL?deliberationRefId=${deliberationRefId}&brand=${brand}&segment=${segment}&precificationType=${precificationType}&statusPortfolio=${statusPortfolio}`
    );
  }

  async getDeliberationPriceListPVUS(
    deliberationRefId,
    brand,
    segment,
    precificationType,
    statusPortfolio
  ) {
    return await axiosInstance.get(
      API_URL_UPL +
      `api/Deliberation/PriceListPVUS?deliberationRefId=${deliberationRefId}&brand=${brand}&segment=${segment}&precificationType=${precificationType}&statusPortfolio=${statusPortfolio}`
    );
  }

  async getDeliberationPPricListMarginFVUSD(
    deliberationRefId,
    brand,
    segment,
    precificationType,
    statusPortfolio
  ) {
    return await axiosInstance.get(
      API_URL_UPL +
      `api/Deliberation/PricListMarginFVUSD?deliberationRefId=${deliberationRefId}&brand=${brand}&segment=${segment}&precificationType=${precificationType}&statusPortfolio=${statusPortfolio}`
    );
  }

  async getDeliberationPPricListMarginFVBRL(
    deliberationRefId,
    brand,
    segment,
    precificationType,
    statusPortfolio
  ) {
    return await axiosInstance.get(
      API_URL_UPL +
      `api/Deliberation/PricListMarginFVBRL?deliberationRefId=${deliberationRefId}&brand=${brand}&segment=${segment}&precificationType=${precificationType}&statusPortfolio=${statusPortfolio}`
    );
  }

  async getDeliberationBlockMargin(
    deliberationRefId,
    brand,
    segment,
    precificationType,
    statusPortfolio
  ) {
    return await axiosInstance.get(
      API_URL_UPL +
      `api/Deliberation/BlockMargin?deliberationRefId=${deliberationRefId}&brand=${brand}&segment=${segment}&precificationType=${precificationType}&statusPortfolio=${statusPortfolio}`
    );
  }

  async getDeliberationBlockMarginUSD(
    deliberationRefId,
    brand,
    segment,
    precificationType,
    statusPortfolio
  ) {
    return await axiosInstance.get(
      API_URL_UPL +
      `api/Deliberation/BlockMarginUSD?deliberationRefId=${deliberationRefId}&brand=${brand}&segment=${segment}&precificationType=${precificationType}&statusPortfolio=${statusPortfolio}`
    );
  }

  async getDeliberationBlockMarginBRL(
    deliberationRefId,
    brand,
    segment,
    precificationType,
    statusPortfolio
  ) {
    return await axiosInstance.get(
      API_URL_UPL +
      `api/Deliberation/BlockMarginBRL?deliberationRefId=${deliberationRefId}&brand=${brand}&segment=${segment}&precificationType=${precificationType}&statusPortfolio=${statusPortfolio}`
    );
  }

  async getDeliberationPricListMarginPVUSD(
    deliberationRefId,
    brand,
    segment,
    precificationType,
    statusPortfolio
  ) {
    return await axiosInstance.get(
      API_URL_UPL +
      `api/Deliberation/PricListMarginPVUSD?deliberationRefId=${deliberationRefId}&brand=${brand}&segment=${segment}&precificationType=${precificationType}&statusPortfolio=${statusPortfolio}`
    );
  }

  async getDeliberationPricListMarginPVBRL(
    deliberationRefId,
    brand,
    segment,
    precificationType,
    statusPortfolio
  ) {
    return await axiosInstance.get(
      API_URL_UPL +
      `api/Deliberation/PricListMarginPVBRL?deliberationRefId=${deliberationRefId}&brand=${brand}&segment=${segment}&precificationType=${precificationType}&statusPortfolio=${statusPortfolio}`
    );
  }

  async getLastMonthWeightedPriceListAndPerformance(
    deliberationRefId,
    brand,
    segment,
    precificationType,
    statusPortfolio
  ) {
    return await axiosInstance.get(
      API_URL_UPL +
      `api/Deliberation/LastMonthWeightedPriceListAndPerformance?deliberationRefId=${deliberationRefId}&brand=${brand}&segment=${segment}&precificationType=${precificationType}&statusPortfolio=${statusPortfolio}`
    );
  }

  async getLastMonthPriceListMain(
    deliberationRefId,
    brand,
    segment,
    precificationType,
    statusPortfolio
  ) {
    return await axiosInstance.get(
      API_URL_UPL +
      `api/Deliberation/LastMonthPriceListMain?deliberationRefId=${deliberationRefId}&brand=${brand}&segment=${segment}&precificationType=${precificationType}&statusPortfolio=${statusPortfolio}`
    );
  }

  async getDeliberationOnePage(
    deliberationRefId,
    brand,
    segment,
    precificationType,
    statusPortfolio
  ) {
    return await axiosInstance.get(
      API_URL_UPL +
      `api/Deliberation/OnePage?deliberationRefId=${deliberationRefId}&brand=${brand}&segment=${segment}&precificationType=${precificationType}&statusPortfolio=${statusPortfolio}`
    );
  }

  async getDeliberationAssumptions(
    deliberationRefId,
    brand,
    segment,
    precificationType,
    statusPortfolio
  ) {
    return await axiosInstance.get(
      API_URL_UPL +
      `api/Deliberation/Assumptions?deliberationRefId=${deliberationRefId}&brand=${brand}&segment=${segment}&precificationType=${precificationType}&statusPortfolio=${statusPortfolio}`
    );
  }

  async getDeliberationDiscountApprovalHierarchys(
    deliberationRefId,
    brand,
    segment,
    precificationType,
    statusPortfolio
  ) {
    return await axiosInstance.get(
      API_URL_UPL +
      `api/Deliberation/DiscountApprovalHierarchys?deliberationRefId=${deliberationRefId}&brand=${brand}&segment=${segment}&precificationType=${precificationType}&statusPortfolio=${statusPortfolio}`
    );
  }

  async getDeliberationDeliberation(
    deliberationRefId,
    brand,
    segment,
    precificationType,
    statusPortfolio
  ) {
    return await axiosInstance.get(
      API_URL_UPL +
      `api/Deliberation/Deliberation?deliberationRefId=${deliberationRefId}&brand=${brand}&segment=${segment}&precificationType=${precificationType}&statusPortfolio=${statusPortfolio}`
    );
  }

  async getProducts() {
    return await axiosInstance.get(API_URL_UPL + "api/produtos");
  }

  async getRelatorio() {
    return await axiosInstance.get(API_URL_UPL + "api/relatorio", {
      responseType: "blob",
    });
  }

  async getExternalTemplate() {
    return await axiosInstance.get(API_URL_UPL + "api/ExternalFiles/Template", {
      responseType: "blob",
    });
  }

  async getRelatorioDeliberation() {
    return await axiosInstance.get(API_URL_UPL + "api/relatorio/deliberation", {
      responseType: "blob",
    });
  }

  async getRelatorioFinalDeliberation() {
    return await axiosInstance.get(
      API_URL_UPL + "api/relatorio/deliberation/final",
      { responseType: "blob" }
    );
  }

  async getEmptyProduct() {
    return await axiosInstance.get(API_URL_UPL + "api/produtos/empty");
  }

  async getProductById(id) {
    return await axiosInstance.get(API_URL_UPL + `api/produtos/${id}`);
  }

  async getCustoReposicao() {
    return await axiosInstance.get(API_URL_UPL + "api/CustoReposicaoFiles");
  }

  async getExternalFiles() {
    return await axiosInstance.get(API_URL_UPL + "api/ExternalFiles");
  }

  async getDeliberationApp() {
    return await axiosInstance.get(API_URL_UPL + "api/DeliberationApplication");
  }

  async getInternalFiles() {
    return await axiosInstance.get(API_URL_UPL + "api/InternalFiles");
  }

  async getGraficoPesoVariavel(name) {
    return await axiosInstance.get(API_URL_UPL + `api/PesoVariaveis/${name}`);
  }

  async getGraficoCustoParaPrecificacao(name) {
    return await axiosInstance.get(
      API_URL_UPL + `api/GraficoCustoParaPrecificacao/${name}`
    );
  }

  async getGraficoCustoParaPrecificacaoById(id) {
    return await axiosInstance.get(
      API_URL_UPL + `api/GraficoCustoParaPrecificacao/id/${id}`
    );
  }

  async getGraficoHistoricoDesempenho(name) {
    return await axiosInstance.get(
      API_URL_UPL + `api/GraficoHistoricoDesempenho/${name}`
    );
  }

  async getCustoReposicaoCompletoFiles(name) {
    return await axiosInstance.get(
      API_URL_UPL + `api/CustoReposicaoCompletoFiles/${name}`
    );
  }

  async getHistoricoIaVariavel() {
    return await axiosInstance.get(API_URL_UPL + "api/HistoricoIA");
  }

  async getUpdateHistory(startDate, endDate) {
    return await axiosInstance.get(
      API_URL_UPL +
      `api/ProductsUpdatesHistory/byDate?startDate=${startDate}&endDate=${endDate}`
    );
  }

  async getVariableWightChartByProjectionReference(
    projectionReference,
    idCompany
  ) {
    return await axiosInstance.get(
      API_URL_UPL +
      `api/PesoVariaveis/ByProjectionReference?projectionReference=${projectionReference}&idCompany=${idCompany}`
    );
  }

  async downloadLastFileInputed(title) {
    return await axiosInstance.get(
      API_URL_UPL + `api/Archive/download/${title}`,
      { responseType: "blob" }
    );
  }

  async getLastDateFileByTitle(
    s3Title
  ) {
    return await axiosInstance.get(
      API_URL_UPL +
      `api/Archive/getLastDateFileByTitle/${s3Title}`
    );
  }

  async getDeliberationTargetMarketSegmentation() {
    return await axiosInstance.get(
      API_URL_UPL + 'api/AgrupamentoMacroMicro/GetAllMacroGroupments',
    );

  }

  async getAgroupmentById(id) {
    return await axiosInstance.get(API_URL_UPL + `api/AgrupamentoMacroMicro/GetMacroGroupmentById/${id}`)
  }
  async getDeliberationTargetMarketSegmentationTable() {
    return await axiosInstance.get(API_URL_UPL + 'api/AgrupamentoMacroMicro/GetDeliberationTargetMarketSegmentationTable')
  }

  async extractRegionalMatrix() {
    return await axiosInstance.get(API_URL_UPL + 'api/Relatorio/Deliberation/RegionalizationMatrix');
  }

  async getPackaging(brand) {
    return await axiosInstance.get(API_URL_UPL + `api/CustoReposicaoFiles/${brand}`)
  }

  async getDeliberationDiscountMatrix(
    deliberationRefId,
    brand,
    segment,
    precificationType,
    statusPortfolio
  ) {
    return await axiosInstance.get(
      API_URL_UPL + `api/Deliberation/DiscountMatrix?deliberationRefId=${deliberationRefId}&brand=${brand}&segment=${segment}&precificationType=${precificationType}&statusPortfolio=${statusPortfolio}`,
    );
  }

  async getDeliberationRegionalizationMatrix(
    deliberationRefId,
    brand,
    segment,
    precificationType,
    statusPortfolio
  ) {
    return await axiosInstance.get(
      API_URL_UPL + `api/Deliberation/RegionalizationMatrix?deliberationRefId=${deliberationRefId}&brand=${brand}&segment=${segment}&precificationType=${precificationType}&statusPortfolio=${statusPortfolio}`,
    );
  }
  

  async getSegmentationTableReport() {
    return await axiosInstance.get(API_URL_UPL + 'api/AgrupamentoMacroMicro/ExportMacroMicroCluster', { responseType: "blob" });
  }
  
  async postGraficoHistoricoDesempenhoImport(file) {
    return await axiosInstance.post(
      API_URL_UPL + "api/GraficoHistoricoDesempenho",
      file
    );
  }

  async postGraficoCustoParaPrecificacaoImport(file) {
    return await axiosInstance.post(
      API_URL_UPL + "api/GraficoCustoParaPrecificacao",
      file
    );
  }

  async postGraficoAtingimentoFinReviewImport(file) {
    return await axiosInstance.post(
      API_URL_UPL + "api/GraficoAtingimentoFinReview",
      file
    );
  }

  async postCustoReposicaoCompletoFilesImport(file) {
    return await axiosInstance.post(
      API_URL_UPL + "api/CustoReposicaoCompletoFiles",
      file
    );
  }

  async postBaseExtractsImport(file) {
    return await axiosInstance.post(API_URL_UPL + "api/BaseExtracts", file);
  }

  async postInternalImport(file) {
    return await axiosInstance.post(API_URL_UPL + "api/InternalFiles", file);
  }

  async postCustoReposicaoImport(file) {
    return await axiosInstance.post(
      API_URL_UPL + "api/CustoReposicaoFiles",
      file
    );
  }

  async postExternalImport(file) {
    return await axiosInstance.post(API_URL_UPL + "api/ExternalFiles", file);
  }

  async createProduct(product) {
    return await axiosInstance.post(API_URL_UPL + `api/produtos`, product, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  async addHistoricoIaVariavel(variavel) {
    return await axiosInstance.post(API_URL_UPL + `api/HistoricoIA`, variavel, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  async recalculateProduct() {
    return await axiosInstance.post(
      API_URL_UPL + `api/produtos/recalcularProdutos`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  async generateDeliberationApp(isNextMonth) {
    return await axiosInstance.post(
      API_URL_UPL +
      `api/DeliberationApplication/AddDeliberationsApp?dateOption=${isNextMonth}`
    );
  }

  async saveUpdateHistory(request) {
    return await axiosInstance.post(
      API_URL_UPL + "api/ProductsUpdatesHistory",
      request
    );
  }

  async uploadsS3Bucket(request) {
    return await axiosInstance.post(API_URL_UPL + "api/Archive", request);
  }

  async addAgroupment(request) {
    return await axiosInstance.post(
      API_URL_UPL + 'api/AgrupamentoMacroMicro/CreateGroupment', request
    );
  }

  async updateSobreProduct(id, sobre) {
    let data = {
      segmento: sobre.segmento,
      classificacao: sobre.classificacao,
      culturaDriver: sobre.culturaDriver,
    };

    return await axiosInstance.patch(
      API_URL_UPL + `api/produtos/sobre/${id}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  async updatMercadoProduct(id, mercado) {
    let data = {
      marketShare: mercado.marketShare,
      potencialAreaTratada: mercado.potencialAreaTratada,
    };

    return await axiosInstance.patch(
      API_URL_UPL + `api/produtos/mercado/${id}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  async updateInfo(id, cardType, newMessage) {
    return await axiosInstance.patch(
      API_URL_UPL + `api/produtos/${cardType}/${id}?newMessage=${newMessage}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  async updateAgroupment(id, request) {
    return await axiosInstance.patch(API_URL_UPL + `api/AgrupamentoMacroMicro/UpdateMacroGroupment/${id}/Partial`, request)
  }
  
  async updateAIPrice() {
    return await axiosInstance.put(
      API_URL_UPL + `api/Deliberation/updateDeliberationAIPrice`
    );
  }

  async updatetVariaveisTributacao(variaveisTributacao) {
    return await axiosInstance.put(
      API_URL_UPL + `api/VariaveisTributacao`,
      variaveisTributacao,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  async updateKeepOnListOrPriceBook(product) {
    return await axiosInstance.put(
      API_URL_UPL + `api/Produtos/updateKeepOnListOrPriceBook`,
      product,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  async updateDeliberationDeliberation(deliberation) {
    return await axiosInstance.put(
      API_URL_UPL + `api/Deliberation/deliberation`,
      deliberation,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  async updateProduct(product) {
    return await axiosInstance.put(
      API_URL_UPL + `api/produtos/${product.id}`,
      product,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  async UpdateConcorrente(product) {
    return await axiosInstance.put(
      API_URL_UPL + `api/produtos/concorrente/${product.id}`,
      product,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  async updateDeliberationDiscountMatrix(request) {
    return await axiosInstance.put(
      API_URL_UPL + 'api/Deliberation/DiscountMatrix', request
    );
  }
  
  async updateDeliberationRegionalizationMatrix(request) {
    return await axiosInstance.put(
      API_URL_UPL + 'api/Deliberation/RegionalizationMatrix', request
    );
  }

  async updatePack(id, request) {
    return await axiosInstance.put(API_URL_UPL + `api/CustoReposicaoFiles/${id}`, request)
  }

  async deleteProduct(id) {
    return await axiosInstance.delete(API_URL_UPL + `api/produtos/${id}`);
  }

  async deleteDeliberation() {
    return await axiosInstance.delete(
      API_URL_UPL + "api/Deliberation/DeleteDeliberation"
    );
  }

  async deleteAgroupmentById(id) {
    return await axiosInstance.delete(API_URL_UPL + `api/AgrupamentoMacroMicro/${id}`)
  }
}

import Vue from "vue";
import Vuex from "vuex";
import userStore from "./user-store";
import companyStore from "./company-store";
import createPersistedState from "vuex-persistedstate";
import predifutStore from "./predifut-store";
import aiPricingStore from "./ai-pricing-store";
import snackbarStore from "./snackbar-store.js";
import snackbarV2 from "./snackbar-v2-store.js"
import fujiokaPricingStore from "./fujioka-pricing-store.js"
import companyConfigStore from "./company-config-store";
import pricingRule from "./pricing-rule.js";
import PredimonitorV2 from "./predimonitor-v2-store.js";
import pricerStore from "./pricer-store.js"
import copilotStore from "./copilot/copilot-store";
import SecureLS from "secure-ls";
import AnnouncementMapV2Store from "./predimonitor/components/announcement-map-v2-store";
import ProductComparisionStore from "./predimonitor/components/product-comparision-store";
import PredimonitorStore from "./predimonitor/predimonitor-store";
const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: [
        "userStore",
        "companyStore",
        "predifutStore",
      ],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  state: {
  },
  getters: {},
  mutations: {
  },
  actions: {},
  modules: {
    userStore,
    companyStore,
    predifutStore,
    snackbarStore,
    aiPricingStore,
    fujiokaPricingStore,
    PredimonitorV2,
    snackbarV2,
    companyConfigStore,
    pricingRule,
    pricerStore,
    AnnouncementMapV2Store,
    copilotStore,
    ProductComparisionStore,
    PredimonitorStore
  },
});

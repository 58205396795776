import { render, staticRenderFns } from "./main-rule-item.vue?vue&type=template&id=545e6b23&scoped=true"
import script from "./main-rule-item.vue?vue&type=script&lang=js"
export * from "./main-rule-item.vue?vue&type=script&lang=js"
import style0 from "./main-rule-item.vue?vue&type=style&index=0&id=545e6b23&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "545e6b23",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VIcon,VSwitch})

<template>
  <div>
    <v-row no-gutters class="my-4">
      <v-col cols="12" md="3"
        ><Prd-text-field
          v-model="model.MinMargin"
          :title="$t('TXT_MIN_MARGIN')"
          :hasTooltip="true"
          :tooltipText="$t('TXT_TOOLTIP_MIN_MARGIN')"
          :type="'number'"
          prefix="%"
          hide-spin-buttons
          class="mr-4"
      /></v-col>
      <v-col cols="12" md="3" title="'Margem máxima'"
        ><Prd-text-field
          v-model="model.MaxMargin"
          :title="$t('TXT_MAX_MARGIN')"
          :type="'number'"
          prefix="%"
          hide-spin-buttons
      /></v-col>
    </v-row>

    <v-checkbox
      v-model="model.AdjustMargin"
      :label="$t('TXT_ENABLE_READJUST_MARGIN')"
      dense
      hide-details
      class="ma-0 pa-0"
    ></v-checkbox>

    <div class="price-warning">
      <v-icon :color="$prdStyles('color-warning')" left
        >mdi-alert-outline</v-icon
      >
      <span>
        {{ $t("TXT_DYNAMIC_PRECIFICATION_ALERT") }}
      </span>
    </div>
  </div>
</template>

<script>
import PrdTextField from "@/components/common/prd-text-field.vue";
export default {
  props: ["index", "values", "idRule"],
  components: { PrdTextField },
  data() {
    return {
      model: {
        MinMargin: null,
        MaxMargin: null,
        AdjustMargin: false,
      },
    };
  },
  watch: {
    model: {
      handler(value) {
        this.updateConditions(value);
      },
      deep: true,
    },
    values: {
      handler(value) {
        if (value.values?.length > 0) this.setInputs(value.values);
      },
      immediate: true,
    },
  },
  methods: {
    updateConditions(inputs) {
      let formatedValue = [
        {
          stringValue: "MinMargin",
          decimalValue: inputs.MinMargin ? parseFloat(inputs.MinMargin) : 0,
          booleanValue: false,
        },
        {
          stringValue: "MaxMargin",
          decimalValue: inputs.MaxMargin ? parseFloat(inputs.MaxMargin) : 0,
          booleanValue: false,
        },
        {
          stringValue: "AdjustMargin",
          decimalValue: 0,
          booleanValue: inputs.AdjustMargin,
        },
      ];
      this.$store.dispatch("updateConditions", {
        idRule: this.idRule,
        key: "updateConditionsDefault",
        conditionType: 2,
        index: this.index,
        values: formatedValue,
      });
    },
    setInputs(conditionList) {
      const MinMargin = conditionList.find(
        (condition) => condition.stringValue == "MinMargin"
      ).decimalValue;
      this.model.MinMargin = MinMargin;

      const MaxMargin = conditionList.find(
        (condition) => condition.stringValue == "MaxMargin"
      ).decimalValue;
      this.model.MaxMargin = MaxMargin;

      const AdjustMargin = conditionList.find(
        (condition) => condition.stringValue == "AdjustMargin"
      ).booleanValue;
      this.model.AdjustMargin = AdjustMargin;
    },
  },
};
</script>

<style lang="scss" scoped>
.price-warning {
  margin-top: 16px;
  text-align: center;
  padding: 8px;
  border: 1px solid #b00020;
  border-radius: 5px;
}
</style>
<template>
  <div class="mt-4">
    <v-row no-gutters>
      <v-checkbox
        v-model="model.UseRange"
        :label="$t('TXT_USE_RANGE')"
        dense
        hide-details
        class="ma-0 pa-0 mr-4"
      ></v-checkbox>
      <v-col cols="12" md="3" class="mr-4"
        ><Prd-text-field
          v-model="model.MaxRange"
          :disabled="!model.UseRange"
          :filled="!model.UseRange"
          :title="$t('TXT_MIN_RANGE')"
          :type="'number'"
          hide-spin-buttons
      /></v-col>
      <v-col cols="12" md="3" class="mr-4"
        ><Prd-text-field
          v-model="model.MinRange"
          :disabled="!model.UseRange"
          :filled="!model.UseRange"
          :title="$t('TXT_MAX_RANGE')"
          :type="'number'"
          hide-spin-buttons
      /></v-col>
    </v-row>
  </div>
</template>

<script>
import PrdTextField from "@/components/common/prd-text-field.vue";
export default {
  props: ["index", "values", "idRule"],
  components: { PrdTextField },
  data() {
    return {
      model: {
        UseRange: false,
        MaxRange: null,
        MinRange: null,
      },
    };
  },

  watch: {
    model: {
      handler(value) {
        this.updateConditions(value);
      },
      deep: true,
    },
    values: {
      handler(value) {
        if (value.values?.length > 0) this.setInputs(value.values);
      },
      immediate: true,
    },
  },
  methods: {
    handlerBehavior(inputs) {
      if (!inputs.UseRange) {
        this.model.MinRange = null;
        this.model.MaxRange = null;
      }
    },
    updateConditions(inputs) {
      this.handlerBehavior(inputs);
      let formatedValue = [
        {
          stringValue: "UseRange",
          decimalValue: 0,
          booleanValue: inputs.UseRange,
        },
        {
          stringValue: "MinRange",
          decimalValue: inputs.MinRange ? parseFloat(inputs.MinRange) : 0,
          booleanValue: false,
        },
        {
          stringValue: "MaxRange",
          decimalValue: inputs.MaxRange ? parseFloat(inputs.MaxRange) : 0,
          booleanValue: false,
        },
      ];
      this.$store.dispatch("updateConditions", {
        idRule: this.idRule,
        key: "updateConditionsDefault",
        conditionType: 11,
        index: this.index,
        values: formatedValue,
      });
    },
    setInputs(conditionList) {
      const UseRange = conditionList.find(
        (condition) => condition.stringValue == "UseRange"
      ).booleanValue;
      this.model.UseRange = UseRange;

      const MinRange = conditionList.find(
        (condition) => condition.stringValue == "MinRange"
      ).decimalValue;
      this.model.MinRange = MinRange;

      const MaxRange = conditionList.find(
        (condition) => condition.stringValue == "MaxRange"
      ).decimalValue;
      this.model.MaxRange = MaxRange;
    },
  },
};
</script>
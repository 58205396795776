<template>
  <v-expansion-panel
    :class="[productBorder(semaphore),'personalized-border' ,'mt-2']"
  >
    <Granularity-header
      :product="product"
      :marginToHeader="marginToHeader"
      :priceToHeader="priceToHeader"
    />

    <Granularity-body
      :product="product"
      :pricingName="pricingName"
      @marginToHeader="marginToHeader = $event"
      @setPriceToHeader="priceToHeader = $event"
      @updatedSemaphore="updatedSemaphore = $event"
    />
  </v-expansion-panel>
</template>

<script>
import GranularityHeader from "./granularity-header.vue";
import GranularityBody from "./granularity-body.vue";

export default {
  components: {
    GranularityHeader,
    GranularityBody,
  },
  props: ["product", "index", "pricingName"],
  data() {
    return {
      pricing: {},
      marginToHeader: null,
      priceToHeader: null,
      updatedSemaphore: null,
    };
  },
  computed: {
    semaphore() {
      return this.updatedSemaphore
        ? this.updatedSemaphore
        : this.product?.semaphore;
    },
  },

  methods: {
    productBorder(semaphore) {
      switch (semaphore) {
        case 0:
          return "info-products";

        case 1:
          return "approved-products";

        case 2:
          return "manual-products";

        case 3:
          return "negative-products";

        case 4:
          return "not-applied-rule-products";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.personalized-border {
  border-left: 6px solid;
}
.approved-products {
  border-color: $feedback-color-success-pure !important;
}
.negative-products {
  border-color: $feedback-color-warning-pure !important;
}
.manual-products {
  border-color: $feedback-color-helper-pure !important;
}
.info-products {
  border-color: $brand-color-primary-pure !important;
}
.not-applied-rule-products {
  border-color: #9510ac !important;
}
</style>
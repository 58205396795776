<template>
  <v-card class="card-relative pa-4">
    <v-row no-gutters justify="space-between" class="mb-4">
      <h4>{{ $i18n.t("TXT_ELASTICITY").toUpperCase() }}</h4>
      <div class="elasticity-header">
        <span class="elasticity-info"
          >{{ $i18n.t("TXT_AVERAGE_PRICE") }}
          <span class="font-weight-bold">{{
            formatMonetary(averagePrice)
          }}</span></span
        >
        <span class="elasticity-info"
          >{{ $i18n.t("TXT_ELASTICITY_MEAN_QUANTITY") }}
          <span class="font-weight-bold">{{
            formatDecimals(averageDemand, 2)
          }}</span></span
        >
        <span class="elasticity-info"
          >{{ $i18n.t("TXT_TOOLTIP_ELASTICITY_TYPE_TITLE") }}
          <span class="font-weight-bold"
            >{{ elasticityType }}
            <Prd-tooltip :text="'TXT_TOOLTIP_ELASTICITY_TYPE'" /></span
        ></span>
      </div>
    </v-row>

    <Prd-loading-circular v-if="isLoading" class="div-centered" />

    <div class="main-info" v-else>
      <!-- MIN ZONE -->
      <Elasticity-card
        :title="$i18n.t('TXT_ELASTICITY_MIN_ZONE')"
        :tooltip="'TXT_TOOLTIP_ELASTICITY_MIN_ZONE'"
        :price="ajustedPrice_MinNeutralZonePrice"
        :demand="ajustedPrice_MinNeutralZoneDemand"
        :profit="ajustedPrice_MinNeutralZoneRevenue"
      />

      <!-- OPTIMIZED/MANUAL -->
      <Elasticity-card
        :title="adjustedCardTitle"
        :tooltip="adjustedCardTooltip"
        :price="ajustedPrice"
        :demand="ajusted_Price_Demand"
        :profit="ajusted_Price_Receita"
        :useDestaq="useDestaq"
        :loading="loadingSkeleton"
      />

      <!-- MAX ZONE -->
      <Elasticity-card
        :title="$i18n.t('TXT_ELASTICITY_MAX_ZONE')"
        :tooltip="'TXT_TOOLTIP_ELASTICITY_MAX_ZONE'"
        :price="ajustedPrice_MaxNeutralZonePrice"
        :demand="ajustedPrice_MaxNeutralZoneDemand"
        :profit="ajustedPrice_MaxNeutralZoneRevenue"
      />
    </div>
  </v-card>
</template>
 
<script>
import service from "@/service/pricer/product-list.js";
const Service = new service();
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
import { formatMonetary } from "@/utils/format-toMonetary.js";
import { formatDecimals } from "@/utils/format-decimals.js";
import PrdTooltip from "@/Design_System/common/prd-tooltip.vue";
import ElasticityCard from "./elasticity-card.vue";

export default {
  components: { PrdTooltip, PrdLoadingCircular, ElasticityCard },
  props: ["idEnterprisePricesProjection"],
  data() {
    return {
      isLoading: false,
      loadingSkeleton: false,
      data: [],
    };
  },

  computed: {
    isManual() {
      return this.data?.isManual ?? false;
    },
    isAIPrice() {
      return this.data?.isAIPrice ?? false;
    },
    useDestaq() {
      return !this.isManual && !this.isAIPrice;
    },

    adjustedCardTitle() {
      let translation;

      if (this.isManual) {
        translation = "TXT_MANUAL_PRICE";
      } else if (this.isAIPrice) {
        translation = "TXT_IA_PRICE_TITLE";
      } else {
        translation = "TXT_ELASTICITY_OPTIMIZED_PRICE";
      }

      return this.$i18n.t(translation);
    },
    adjustedCardTooltip() {
      let translation;

      if (this.isManual) {
        translation = "TXT_MANUAL_PRICE_INPUT";
      } else if (this.isAIPrice) {
        translation = "TXT_AI_PRICE_TOOLTIP";
      } else {
        translation = "TXT_TOOLTIP_ELASTICITY_OPTIMIZED";
      }

      return this.$i18n.t(translation);
    },
    averagePrice() {
      return this.data?.averagePrice ?? "-";
    },
    averageDemand() {
      return this.data?.averageDemand ?? "-";
    },

    // MIN ZONE
    ajustedPrice_MinNeutralZonePrice() {
      return this.data?.ajustedPrice_MinNeutralZonePrice ?? "-";
    },
    ajustedPrice_MinNeutralZoneDemand() {
      return this.data?.ajustedPrice_MinNeutralZoneDemand ?? "-";
    },
    ajustedPrice_MinNeutralZoneRevenue() {
      return this.data?.ajustedPrice_MinNeutralZoneRevenue ?? "-";
    },

    // ADJUSTED PRICE
    ajustedPrice() {
      return this.data?.ajustedPrice ?? "-";
    },
    ajusted_Price_Demand() {
      return this.data?.ajusted_Price_Demand ?? "-";
    },
    ajusted_Price_Receita() {
      return this.data?.ajusted_Price_Receita ?? "-";
    },

    // MAX ZONE
    ajustedPrice_MaxNeutralZonePrice() {
      return this.data?.ajustedPrice_MaxNeutralZonePrice ?? "-";
    },
    ajustedPrice_MaxNeutralZoneDemand() {
      return this.data?.ajustedPrice_MaxNeutralZoneDemand ?? "-";
    },
    ajustedPrice_MaxNeutralZoneRevenue() {
      return this.data?.ajustedPrice_MaxNeutralZoneRevenue ?? "-";
    },

    elasticityType() {
      if (!this.data?.elastity) return "-";

      if (this.data.elastity) {
        if (this.data.elastity < 1 && this.data.elastity > -1)
          return this.$i18n.t("TXT_INELASTIC");
        else return this.$i18n.t("TXT_ELASTIC");
      } else {
        return null;
      }
    },
  },

  methods: {
    formatMonetary,
    formatDecimals,

    async getElasticity(isFromOptimizedPrice) {
      if (!isFromOptimizedPrice) this.isLoading = true;
      else this.loadingSkeleton = true;

      try {
        let response = await Service.getElasticity(
          this.idEnterprisePricesProjection
        );

        this.data = response?.data?.result ?? null;
        this.$emit("setPriceToHeader", this.data?.ajustedPrice);
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_VARIATIONPROFITRESET_ELASTICITY_EMPTY"),
          type: "error",
        });
        this.data = null;
      }

      this.loadingSkeleton = false;
      this.isLoading = false;
    },
  },
  created() {
    this.getElasticity();
  },
};
</script>

<style lang="scss" scoped>
.div-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.skeleton-loader {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.card-relative {
  position: relative;
}

.elasticity-header {
  font-size: 12px;
  display: flex;
  gap: 1rem;

  .elasticity-info {
    font-size: 14px;
  }
}

.main-info {
  display: flex;
  gap: 0.5rem;
}
</style>
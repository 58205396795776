const initialValues = {
  email: null,
  hasUser: false,
  rememberLogin: false,
  useRefreshToken: null,
  adminRoles: [],
  id: null,
  username: null,
  phone: null,
  companyName: null,
  vemUserType: null
};

const user = {
  state: { ...initialValues },

  getters: {
    hasUser: (state) => state.hasUser,
    userData: (state) => state,
    isPredifyAdmin: (state) => {
      return state.adminRoles.includes("1003");
    },
    adminRoles: (state) => {
      return state.adminRoles
    },
    readOnlyUPL: (state) => {
      return state.adminRoles.includes('1004');
    },
    Acesso_E_Edicao_UPL_Geral: (state) => { return state.adminRoles.includes('2000'); },
    Acesso_E_Edicao_UPL_One_Page: (state) => { return state.adminRoles.includes('2001'); },
    Acesso_UPL_Leitor: (state) => { return state.adminRoles.includes('2002'); },
    vemManager: (state) => { return state.vemUserType == "GERENTE" || state.vemUserType == "COORDENADOR" }
  },

  mutations: {
    setUser(state, data) {
      state.email = data.email;
      state.hasUser = data.hasUser;
      state.rememberLogin = data.rememberLogin;
      state.useRefreshToken = data.useRefreshToken;
      state.token = data.token;
      state.idCompany = data.idCompany;
      state.adminRoles = data.adminRoles;
      state.username = data.username;
      state.phone = data.phone;
      state.id = data.id;
      state.companyName = data.companyName;
    },

    clear(state) {
      Object.assign(state, initialValues);
    },

    setVemUser(state, userType) {
      if (userType) state.vemUserType = userType?.description?.toUpperCase() ?? null
    }
  },

  actions: {
    setUser(context, data) {
      context.commit("setUser", data);
    },
    clear(context) {
      context.commit("clear");
    },
  },
};

export default user;

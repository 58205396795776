<template>
  <div class="mt-3">
    <label v-show="!hideLabel" class="font-weight-bold" for="">{{
      author
    }}</label>
    <div
      class="message"
      :class="[
        author == 'Copilot' ? 'received' : 'sent',
        opacityText ? 'opacity-text' : '',
      ]"
    >
      <div v-html="htmlContent" class="mb-n4"></div>
    </div>
  </div>
</template>

<script>
import { marked } from "marked";
import katex from 'katex';
import 'katex/dist/katex.min.css';
export default {
  props: {
    text: {
      type: String,
    },
    author: {
      type: String,
      default: "",
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    opacityText: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    htmlContent() {
      if (!this.text) return "";

      const renderer = new marked.Renderer();
      renderer.code = (code, language) => {
        if (language === "math") {
          return katex.renderToString(code, {
            throwOnError: false,
          });
        }
        return `<pre><code>${code}</code></pre>`;
      };

      return marked(this.text, { renderer });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0;
}
.message {
  padding: 10px;
  margin: 4px 4px;
  border-radius: 10px;
  max-width: 100%;
  position: relative;
}

.message.sent {
  background-color: #d4e1ffbd;
  align-self: flex-end;
  border-bottom-right-radius: 0;
}

.message.received {
  background-color: #ffffff;
  align-self: flex-start;
  border-bottom-left-radius: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.opacity-text {
  opacity: 0.8;
}
</style>
<template>
  <v-dialog v-model="isModalOpen" max-width="800">
    <v-card class="pa-4">
      <h4 class="mb-4">COPILOT RESPONSE</h4>
      <pre>{{ formattedJson }}</pre>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      isModalOpen: false,
    };
  },

  computed: {
    copilotOperations() {
      return this.$store?.state?.copilotStore?.copilotOperations ?? null;
    },

    formattedJson() {
      return JSON.stringify(this.copilotOperations, null, 2);
    },

    isPredifyAdmin() {
      return this.$store?.getters?.isPredifyAdmin ?? false;
    },
  },

  methods: {
    handleKeyPress(event) {
      if (event.ctrlKey && event.key === "i" && this.isPredifyAdmin) {
        this.isModalOpen = !this.isModalOpen;
      }
    },
  },

  mounted() {
    window.addEventListener("keydown", this.handleKeyPress);
  },

  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeyPress);
  },
};
</script>

<style scoped>
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
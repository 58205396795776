/**
     * Create a new object to the function ADD_MESSAGE in store.
     * @param {string} author - Message author
     * @param {string} message - Message text
     */
export function createMessage(author, message) {
    return {
        author: author,
        messages: [
            {
                type: 'text',
                message: message
            }
        ]
    }
}

export function buildRequestRules(idCompany, model) {
    return {
        categories: [],
        enterprisePricingType: 0,
        products: [],

        idCompany: idCompany,
        enterprise_ElasticityStatus: model?.RuleGroup?.Elasticity ?? 0,
        description: model.RuleGroup.Description,
        allowAbcCurve: model?.RuleGroup?.AllowAbcCurve ?? "ABC",
        idRule: model.RuleGroup.Id || 0,
        isActive: true,
        isAllProducts: true,
        isBlocked: false,
        isSimulation: false,
        numberOrder: 0,

        filters: createFilters(model.RuleGroup.Filters),
        conditions: createConditions(model.RuleGroup.Rules)
    }
}
const createConditions = (conditions) => {
    if (!conditions) return []

    return conditions.map((condition, index) => ({
        conditionType: condition.Type || 0,
        idCondition: 0,
        isActive: true,
        numberOrder: index,
        values: condition.Values
    }))
}
const createFilters = (filters) => {
    if (!filters) return []

    return filters.map((el) => ({
        idEnterprisePriceGroup_DefaultFilter: el.Id,
        value: el.Value
    }))
}

export function buildRequestCreateRuleInGroup(idCompany, idRule, rule) {
    return {
        IdCompany: idCompany,
        IdRule: idRule,
        Condition: {
            ConditionType: rule.Type,
            Values: rule.Values,
            Order: 0
        },
    }
}

export function compareRuleList(oldList, newList) {
    return newList.filter(obj2 => {
        return !oldList.some(obj1 => obj1.idRule === obj2.idRule);
    });
}

export function changedRuleIds(newRules, copilotOperations) {
    let idList = []
    copilotOperations.forEach((el) => {
        if (el.RuleGroup.Id) idList.push(el.RuleGroup.Id)
    })

    newRules.forEach((el) => {
        if (el.idRule) idList.push(el.idRule)
    })

    return idList
}
<template>
  <v-row no-gutters align="center" class="mr-1">
    <p class="ma-0">
      <span v-if="title != 'Ouro'" >|</span> {{ title }}:
      <span class="font-weight-bold"> {{ formatMonetary(value) }}</span>
    </p>
    <Prd-tooltip-informations v-if="showtooltip" :text="tooltipContent" />
  </v-row>
</template>

<script>
import PrdTooltipInformations from "@/components/common/prd-tooltip.vue";
import { formatMonetary } from "@/utils/format-toMonetary.js";
import { formatPercentage } from "@/utils/format-percentage.js";

export default {
  components: { PrdTooltipInformations },

  props: {
    title: { default: "Desconhecido" },
    value: { default: "R$ 00,00" },
    margin: { default: "0%" },
  },

  methods: {
    formatMonetary,
    formatPercentage,
  },

  computed: {
    tooltipContent() {
      return `<p class="text-subtitle-1 ma-0 font-weight-bold">${this.$i18n.t(
        "TXT_MARGIN"
      )}: ${this.formatPercentage(this.margin)}</p>`;
    },

    showtooltip() {
      return this.margin && this.margin != "-";
    },
  },
};
</script>
<template>
  <v-dialog v-model="isOpen" max-width="470">
    <v-card class="pa-4">
      <h2 class="mb-6">{{ $t("TXT_FAIL_WHEN_IMPORT") }}</h2>
      <p>{{ $t("TXT_NON_STANDARD") }}</p>
      <p>{{ $t("TXT_TO_SEE_PATTERN_ALERT") }}</p>
      <h4 class="mb-2" v-if="errors.length > 0">{{ $t("TXT_ERRORS") }}</h4>
      <p class="red--text ma-0" v-for="error in errors" :key="error">
        {{ error }}
      </p>
      <v-row no-gutters justify="end" class="mt-6">
        <Prd-btn
          :outlined="true"
          :title="$t('TXT_CLOSE')"
          @click="isOpen = false"
        />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
export default {
  components: {
    PrdBtn,
  },
  data() {
    return {
      isOpen: false,
      errors: [],
    };
  },
  watch: {
    isOpen: {
      handler(value) {
        if (!value) this.closeModal();
      },
    },
  },
  methods: {
    openModal(message) {
      this.isOpen = true;

      const splitedMessage = message.split("-")

      const translatedMessage = message.startsWith("TXT_")
        ? splitedMessage.length > 0
          ? splitedMessage.length == 3
            ? this.$i18n.t(splitedMessage[0], {
                field: this.$i18n.t(splitedMessage[1]),
                sku: splitedMessage[2],
              })
            : this.$i18n.t(splitedMessage[0], {
                numberColumn: splitedMessage[1],
                expected: splitedMessage[2],
                founded: splitedMessage[3],
              })
          : this.$i18n.t(message)
        : message;

      this.errors.push(translatedMessage);
    },
    closeModal() {
      this.isOpen = false;
      this.errors = [];
    },
  },
};
</script>
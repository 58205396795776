<template>
  <div>
    <div class="mb-8">
      <h4 class="pt-4">{{ $t("TXT_FILTERS") }}</h4>

      <Filters-main
        :ref="filtersRef"
        :iaPriceLoading="iaPriceLoading"
        :isAdminUpl="isAdminUpl"
        :isLoadingFather="isLoadingFather"
        @openSelectDeliberationModal="openSelectDeliberationModal"
        @genereteTable="genereteTable"
        @setLoading="$emit('setLoading', $event)"
        class="my-4"
      />

      <v-row no-gutters class="mt-7">
        <v-col>
          <v-row no-gutters class="gap-4">
            <prd-btn
              @click="relatorio"
              :outlined="true"
              :title="$t('TXT_REPORT')"
              :disabled="iaPriceLoading || isLoadingFather"
            />

            <prd-btn
              @click="genereteTable"
              :disabled="iaPriceLoading || isLoadingFather"
              :title="$t('TXT_APPLY_FILTERS')"
            />

            <prd-btn
              v-if="isAdminUpl"
              @click="updateAIPrice"
              :title="$t('TXT_UPDATE_AI_PRICE')"
              :disabled="iaPriceLoading || isLoadingFather"
              :loading="iaPriceLoading"
            />
          </v-row>
        </v-col>
        <prd-btn
          v-if="isAdminUpl"
          @click="isOpenModalNewDeliberation = true"
          :outlined="false"
          :title="$t('TXT_SAVE_DELIBERATION')"
          :disabled="iaPriceLoading || isLoadingFinalReport || isLoadingFather"
          :loading="isLoadingFinalReport"
        />
      </v-row>
    </div>
    <div>
      <span v-if="this.valueFilters != null">{{
        valueFilters.brand + " " + valueFilters.segment
      }}</span>

      <v-tabs
        v-model="tab"
        color="black"
        :slider-color="$prdStyles('color-secundary')"
        class="group-filters"
        height="30px"
        show-arrows
        center-active
      >
        <v-tab v-for="(table, index) in tables" :key="index" class="mr-4">
          <v-badge :value="showBadge(table.name)" color="red" dot>
            <span>
              {{ table.name }}
            </span>
          </v-badge>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(table, index) in tables" :key="index">
          <table-data
            :headers="table.headers"
            :items="table.items"
            :name="table.name"
            :subtitle="table.subtitle"
            @setLoading="$emit('setLoading', $event)"
            @updateOriginalData="updateOriginalData(index, $event)"
            @updateOriginalItemsModified="
              updateOriginalItemsModified(index, $event)
            "
            @updateMarketingVariationMatrix="updateMarketingVariationMatrix"
            @hasNewValues="hasNewValues = $event"
            @setBadgeDiscountMatrix="inEditModeDiscountMatrix = $event"
            @setBadgeRegionalizationMatrix="
              inEditModeRegionalizationMatrix = $event
            "
            @refreshTargetMarketSegmentationTable="
              getDeliberationTargetMarketSegmentationTable
            "
          />
        </v-tab-item>
      </v-tabs-items>
    </div>

    <select-deliberation-modal
      @resetDeliberation="resetDeliberation"
      @saveNewDeliberation="saveNewDeliberation"
      :ref="selectDeliberationModalRef"
    />

    <create-deliberation-modal
      v-if="isOpenModalNewDeliberation"
      @closeModal="isOpenModalNewDeliberation = false"
      @createNewDeliberation="createNewDeliberation"
      :isLoading="isLoadingFinalReport"
    />
  </div>
</template>

<script>
import tableData from "../tables/table-data.vue";
import UPLService from "@/service/upl";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import SelectDeliberationModal from "../select-deliberation-modal.vue";
import CreateDeliberationModal from "../create-deliberation-modal.vue";
import FiltersMain from "./components/filters-main.vue";
export default {
  props: ["isLoadingFather"],
  components: {
    tableData,
    PrdBtn,
    SelectDeliberationModal,
    CreateDeliberationModal,
    FiltersMain,
  },
  data() {
    return {
      service: new UPLService(),
      iaPriceLoading: false,
      tab: 0,
      tables: [],

      valueFilters: null,
      isOpenModalNewDeliberation: false,
      isLoadingFinalReport: false,
      hasNewValues: false,
      inEditModeDiscountMatrix: false,
      inEditModeRegionalizationMatrix: false,

      marketingVariationMatrix: [],
      filtersRef: "filtersComponent",
      selectDeliberationModalRef: "selectDeliberationModalComponent",
    };
  },
  watch: {
    tab: {
      handler(value) {
        if (value >= 5 && this.hasNewValues) {
          this.updateTables(value);
        }
      },
    },
  },
  computed: {
    isAdminUpl() {
      return (
        this.$store.getters.Acesso_E_Edicao_UPL_Geral ||
        this.$store.getters.isPredifyAdmin
      );
    },
  },
  methods: {
    showBadge(name) {
      if (name == "MARKETING VARIATION MATRIX")
        return this.inEditModeRegionalizationMatrix;
      else if (name == "DISCOUNT MATRIX") return this.inEditModeDiscountMatrix;
      else return false;
    },
    updateMarketingVariationMatrix(updatedItems) {
      const index = this.tables.findIndex(
        (table) => table.name == "MARKETING VARIATION MATRIX"
      );
      if (index != -1) {
        updatedItems.forEach((upItem) => {
          const indexItem = this.tables[index].items.findIndex(
            (item) => item.brand == upItem.brand
          );
          if (indexItem != -1) {
            this.tables[index].items[indexItem].portifolioStatus =
              upItem.portifolioStatus;

            this.marketingVariationMatrix = this.tables[index].items;
          }
        });
      }
    },
    async createNewDeliberation(isNextMonth) {
      this.isLoadingFinalReport = true;
      await this.service.generateDeliberationApp(isNextMonth).catch((error) => {
        this.$handleError(error, this.$i18n.t("TXT_ERROR_CREATE_REPORT"));
      });
      await this.service
        .getRelatorioFinalDeliberation()
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: "application/vnd.ms-excel" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Deliberation.xlsx");
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
          this.$store.commit("snackbarV2/set", {
            message: this.$i18n.t("TXT_SUCCESS_CREATE_REPORT"),
            type: "success",
          });
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("snackbarV2/set", {
            message: this.$i18n.t("TXT_ERROR_CREATE_REPORT"),
            type: "error",
          });
        });
      await this.service
        .updateAIPrice()
        .catch((error) => {
          console.log(error);
          this.$store.commit("snackbarV2/set", {
            message: this.$i18n.t("TXT_ERROR_CREATE_REPORT"),
            type: "error",
          });
        })
        .finally(() => {
          this.isLoadingFinalReport = false;
          this.isOpenModalNewDeliberation = false;
          window.location.reload();
        });
    },
    updateOriginalData(indexTable, newData) {
      const indexItem = newData.indexItem;
      const data = newData.newData;
      this.tables[indexTable].items[indexItem] = data;
    },
    updateOriginalItemsModified(indexTable, items) {
      items.forEach((updatedItem) => {
        const originalIndex = this.tables[indexTable].items.findIndex(
          (originalItem) => originalItem.id == updatedItem.id
        );
        if (originalIndex != -1)
          this.tables[indexTable].items[originalIndex] = updatedItem;
        this.discountMatrixExport = this.tables[indexTable].items;
      });
    },
    async updateAIPrice() {
      this.iaPriceLoading = true;
      const res = await this.service.updateAIPrice();

      if (res.statusText == "OK") {
        location.reload(true);
        this.iaPriceLoading = false;
      } else {
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_UPDATE_SEARCH_AI"),
          type: "error",
        });
        this.iaPriceLoading = false;
      }
    },
    async relatorio() {
      await this.service
        .getRelatorioDeliberation()
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: "application/vnd.ms-excel" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Deliberation.xlsx");
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async genereteTable() {
      this.$emit("setLoading", true);
      this.tables = [];

      await this.getInfoTable();
      await this.getLastMonthWeightedPriceListAndPerformance();
      await this.getLastMonthPriceListMain();
      await this.getDeliberationOnePage();
      await this.getDeliberationDeliberation();
      await this.getDeliberationAssumptions();
      await this.getDeliberationPriceListFVUS();
      await this.getDeliberationPriceListPVUS();
      await this.getDeliberationPriceListFVBRL();
      await this.getDeliberationPriceListPVBRL();
      await this.getDeliberationPPricListMarginFVUSD();
      await this.getDeliberationPricListMarginPVUSD();
      await this.getDeliberationPPricListMarginFVBRL();
      await this.getDeliberationPricListMarginPVBRL();
      await this.getDeliberationDiscountMatrix();
      await this.getDeliberationMarketingVariationMatrix();
      await this.getDeliberationTargetMarketSegmentationTable();
      this.$emit("setLoading", false);
    },
    async getInfoTable() {
      const {
        deliberationId,
        brand,
        segment,
        precificationType,
        statusPortfolio,
      } = this.$refs[this.filtersRef].getFilters();
      return await this.service
        .getDeliberationInfo(
          deliberationId,
          brand,
          segment,
          precificationType,
          statusPortfolio
        )
        .then((res) => {
          res.data.items.forEach((el) => {
            el.isEditPriceBook = false;
            el.isEditKeepOnList = false;
            el.isLoading = false;
          });
          this.tables.push(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getLastMonthWeightedPriceListAndPerformance() {
      const {
        deliberationId,
        brand,
        segment,
        precificationType,
        statusPortfolio,
      } = this.$refs[this.filtersRef].getFilters();
      return await this.service
        .getLastMonthWeightedPriceListAndPerformance(
          deliberationId,
          brand,
          segment,
          precificationType,
          statusPortfolio
        )
        .then((res) => {
          this.tables.push(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getLastMonthPriceListMain() {
      const {
        deliberationId,
        brand,
        segment,
        precificationType,
        statusPortfolio,
      } = this.$refs[this.filtersRef].getFilters();
      return await this.service
        .getLastMonthPriceListMain(
          deliberationId,
          brand,
          segment,
          precificationType,
          statusPortfolio
        )
        .then((res) => {
          this.tables.push(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getDeliberationOnePage() {
      const {
        deliberationId,
        brand,
        segment,
        precificationType,
        statusPortfolio,
      } = this.$refs[this.filtersRef].getFilters();
      return await this.service
        .getDeliberationOnePage(
          deliberationId,
          brand,
          segment,
          precificationType,
          statusPortfolio
        )
        .then((res) => {
          this.tables.push(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getDeliberationDeliberation() {
      const {
        deliberationId,
        brand,
        segment,
        precificationType,
        statusPortfolio,
      } = this.$refs[this.filtersRef].getFilters();
      return await this.service
        .getDeliberationDeliberation(
          deliberationId,
          brand,
          segment,
          precificationType,
          statusPortfolio
        )
        .then((res) => {
          res.data.items.forEach((el) => {
            el.isEditUSD = false;
            el.isEditBRL = false;
            el.isEditComments = false;
            el.isLoading = false;
          });
          this.tables.push(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getDeliberationAssumptions() {
      const {
        deliberationId,
        brand,
        segment,
        precificationType,
        statusPortfolio,
      } = this.$refs[this.filtersRef].getFilters();
      return await this.service
        .getDeliberationAssumptions(
          deliberationId,
          brand,
          segment,
          precificationType,
          statusPortfolio
        )
        .then((res) => {
          this.tables.push(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getDeliberationPriceListFVUS() {
      const {
        deliberationId,
        brand,
        segment,
        precificationType,
        statusPortfolio,
      } = this.$refs[this.filtersRef].getFilters();
      return await this.service
        .getDeliberationPriceListFVUS(
          deliberationId,
          brand,
          segment,
          precificationType,
          statusPortfolio
        )
        .then((res) => {
          this.tables.push(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getDeliberationPriceListPVUS() {
      const {
        deliberationId,
        brand,
        segment,
        precificationType,
        statusPortfolio,
      } = this.$refs[this.filtersRef].getFilters();
      return await this.service
        .getDeliberationPriceListPVUS(
          deliberationId,
          brand,
          segment,
          precificationType,
          statusPortfolio
        )
        .then((res) => {
          this.tables.push(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getDeliberationPriceListFVBRL() {
      const {
        deliberationId,
        brand,
        segment,
        precificationType,
        statusPortfolio,
      } = this.$refs[this.filtersRef].getFilters();
      return await this.service
        .getDeliberationPriceListFVBRL(
          deliberationId,
          brand,
          segment,
          precificationType,
          statusPortfolio
        )
        .then((res) => {
          this.tables.push(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getDeliberationPriceListPVBRL() {
      const {
        deliberationId,
        brand,
        segment,
        precificationType,
        statusPortfolio,
      } = this.$refs[this.filtersRef].getFilters();
      return await this.service
        .getDeliberationPriceListPVBRL(
          deliberationId,
          brand,
          segment,
          precificationType,
          statusPortfolio
        )
        .then((res) => {
          this.tables.push(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getDeliberationPPricListMarginFVUSD() {
      const {
        deliberationId,
        brand,
        segment,
        precificationType,
        statusPortfolio,
      } = this.$refs[this.filtersRef].getFilters();
      return await this.service
        .getDeliberationPPricListMarginFVUSD(
          deliberationId,
          brand,
          segment,
          precificationType,
          statusPortfolio
        )
        .then((res) => {
          this.tables.push(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getDeliberationPricListMarginPVUSD() {
      const {
        deliberationId,
        brand,
        segment,
        precificationType,
        statusPortfolio,
      } = this.$refs[this.filtersRef].getFilters();
      return await this.service
        .getDeliberationPricListMarginPVUSD(
          deliberationId,
          brand,
          segment,
          precificationType,
          statusPortfolio
        )
        .then((res) => {
          this.tables.push(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getDeliberationPPricListMarginFVBRL() {
      const {
        deliberationId,
        brand,
        segment,
        precificationType,
        statusPortfolio,
      } = this.$refs[this.filtersRef].getFilters();
      return await this.service
        .getDeliberationPPricListMarginFVBRL(
          deliberationId,
          brand,
          segment,
          precificationType,
          statusPortfolio
        )
        .then((res) => {
          this.tables.push(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getDeliberationPricListMarginPVBRL() {
      const {
        deliberationId,
        brand,
        segment,
        precificationType,
        statusPortfolio,
      } = this.$refs[this.filtersRef].getFilters();
      return await this.service
        .getDeliberationPricListMarginPVBRL(
          deliberationId,
          brand,
          segment,
          precificationType,
          statusPortfolio
        )
        .then((res) => {
          this.tables.push(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getDeliberationDiscountMatrix() {
      const {
        deliberationId,
        brand,
        segment,
        precificationType,
        statusPortfolio,
      } = this.$refs[this.filtersRef].getFilters();
      return await this.service
        .getDeliberationDiscountMatrix(
          deliberationId,
          brand,
          segment,
          precificationType,
          statusPortfolio
        )
        .then((res) => {
          this.tables.push(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getDeliberationMarketingVariationMatrix() {
      const {
        deliberationId,
        brand,
        segment,
        precificationType,
        statusPortfolio,
      } = this.$refs[this.filtersRef].getFilters();
      return await this.service
        .getDeliberationRegionalizationMatrix(
          deliberationId,
          brand,
          segment,
          precificationType,
          statusPortfolio
        )
        .then((res) => {
          res.data.items.forEach((el) => {
            this.marketingVariationMatrix.push({
              "Brand Sales Force": el.segment,
              Marca: el.brand,
              "Diretor de venda": el.commercialDirector,
              "Gerente de BU": el.businessUnit,
              "Gerente Regional": el.supervisorDeNegocios,
              CTC: el.ctc,
            });
          });

          res.data.headers.forEach((el) => {
            el.width = "130px";
          });
          this.tables.push(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getDeliberationTargetMarketSegmentationTable() {
      return await this.service
        .getDeliberationTargetMarketSegmentationTable()
        .then((res) => {
          const foundedIndex = this.tables.findIndex(
            (table) =>
              table.name == "SEGMENTAÇÃO DE MERCADO" &&
              table.subtitle == "AGRUPAMENTOS"
          );
          if (foundedIndex == -1) this.tables.push(res.data);
          else {
            this.tables[foundedIndex].items = res.data.items;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetDeliberation() {
      this.$refs[this.filtersRef].resetFiltersForResetDeliberation();
      this.$refs[this.selectDeliberationModalRef].close();
    },
    async saveNewDeliberation() {
      this.$emit("setLoading", true);
      this.$refs[this.selectDeliberationModalRef].close();
      await this.service.deleteDeliberation();
      this.$refs[this.filtersRef].resetFiltersForNewDeliberation();
      await this.genereteTable();
      this.$emit("setLoading", false);
    },
    async updateTables(tab) {
      this.$emit("setLoading", true);
      this.hasNewValues = false;
      const lastIndex = this.tables.length - 1;
      this.tables.splice(5, lastIndex);
      await this.getDeliberationAssumptions();
      this.tab = tab;
      await this.getDeliberationPriceListFVUS();
      this.tab = tab;
      await this.getDeliberationPriceListPVUS();
      this.tab = tab;
      await this.getDeliberationPriceListFVBRL();
      this.tab = tab;
      await this.getDeliberationPriceListPVBRL();
      this.tab = tab;
      await this.getDeliberationPPricListMarginFVUSD();
      this.tab = tab;
      await this.getDeliberationPricListMarginPVUSD();
      this.tab = tab;
      await this.getDeliberationPPricListMarginFVBRL();
      this.tab = tab;
      await this.getDeliberationPricListMarginPVBRL();
      this.tab = tab;
      await this.getDeliberationDiscountMatrix();
      this.tab = tab;
      this.$emit("setLoading", false);
      this.$store.commit("snackbarV2/set", {
        message: this.$i18n.t("TXT_UPDATED_TABLES"),
        type: "success",
      });
    },
    openSelectDeliberationModal(oldName, newName) {
      this.$refs[this.selectDeliberationModalRef].open(oldName, newName);
    },
  },
};
</script>

<style lang="scss" scoped>
.group-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.281);
}

.v-tab {
  font-size: 14px !important;
  text-transform: inherit;
  font-weight: 600;
}
</style>
import { render, staticRenderFns } from "./registered-users.vue?vue&type=template&id=0e9b6dff&scoped=true"
import script from "./registered-users.vue?vue&type=script&lang=js"
export * from "./registered-users.vue?vue&type=script&lang=js"
import style0 from "./registered-users.vue?vue&type=style&index=0&id=0e9b6dff&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e9b6dff",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})

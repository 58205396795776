<template>
  <v-card class="card-relative pa-4">
    <v-row no-gutters align="center">
      <h4 v-t="'TXT_MARKET_ANALYSIS'"></h4>
      <Competitors-price-modal
        :idEnterprisePricesProjection="idEnterprisePricesProjection"
        :productName="productName"
      />
      <v-spacer></v-spacer>
      <Variable-weights-chart-modal
        v-if="UPL"
        :idCompany="idCompany"
        :projectionReference="projectionReference"
      />
    </v-row>

    <Prd-loading-circular v-if="isLoading" class="div-centered" />

    <div class="main-inputs mt-4" v-else>
      <div class="monitoring-items">
        <label v-t="'TXT_PRODUCT_COST'"></label>
        <div class="input-box">{{ formatMonetary(productCost) }}</div>
      </div>
      <div class="monitoring-items">
        <label v-t="'TXT_CURRENT_PRICE'"></label>
        <div class="input-box">{{ formatMonetary(currentPrice) }}</div>
      </div>
      <div class="main-monitoring">
        <label v-t="'TXT_MARKET_MONITORING_IA'"></label>
        <div class="input-box monitoring px-2">
          <div>
            <v-icon small left :color="$prdStyles('color-warning')"
              >mdi-arrow-down-bold-outline</v-icon
            >
            <span>{{ formatMonetary(marketMinPrice) }}</span>
          </div>
          <div>
            <v-icon small left color="#3C5CA7">mdi-minus-circle-outline</v-icon>
            <span>{{ formatMonetary(marketAvgPrice) }}</span>
          </div>
          <div>
            <v-icon small left color="#37B352"
              >mdi-arrow-up-bold-outline</v-icon
            >
            <span>{{ formatMonetary(marketMaxPrice) }}</span>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
import CompetitorsPriceModal from "./competitors-price-modal.vue";
// import VariableWeightsChartModal from "./variable-weights-chart-modal.vue";

import service from "@/service/pricer/product-list.js";
const Service = new service();
import { formatMonetary } from "@/utils/format-toMonetary.js";
import VariableWeightsChartModal from "./variable-wights-chart/variable-weights-chart-modal.vue";
export default {
  props: ["idEnterprisePricesProjection", "productName", "projectionReference"],
  components: {
    CompetitorsPriceModal,
    PrdLoadingCircular,
    VariableWeightsChartModal,
  },

  data() {
    return {
      isLoading: false,

      data: [],
    };
  },

  computed: {
    productCost() {
      return this.data?.productCost ?? "-";
    },
    currentPrice() {
      return this.data?.currentPrice ?? "-";
    },
    marketMinPrice() {
      return this.data?.marketMinPrice ?? "-";
    },
    marketAvgPrice() {
      return this.data?.marketAvgPrice ?? "-";
    },
    marketMaxPrice() {
      return this.data?.marketMaxPrice ?? "-";
    },
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    UPL() {
      return this.idCompany == 2858 ? true : false;
    },
  },

  methods: {
    formatMonetary,
    async getMarketAnalysis() {
      this.isLoading = true;

      try {
        let response = await Service.getMarketAnalysis(
          this.idEnterprisePricesProjection
        );
        this.data = response?.data?.result ?? null;
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_GET_MARKET_ANALISYS_DATAS"),
          type: "error",
        });
        this.data = null;
      }

      this.isLoading = false;
    },
  },

  created() {
    this.getMarketAnalysis();
  },
};
</script>

<style lang="scss" scoped>
.div-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card-relative {
  position: relative;
}
.main-inputs {
  display: flex;
  gap: 1rem;

  .monitoring-items {
    width: 20%;
  }

  .input-box {
    border: 1px solid #bab8b8;
    border-radius: 5px;
    padding: 5px 0px 5px 5px;
    margin-top: 4px;
  }

  .main-monitoring {
    flex-grow: 1;
    .monitoring {
      display: flex;
      justify-content: space-between;
      > div {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>

import { render, staticRenderFns } from "./copilot-text-area.vue?vue&type=template&id=7792fc9c&scoped=true"
import script from "./copilot-text-area.vue?vue&type=script&lang=js"
export * from "./copilot-text-area.vue?vue&type=script&lang=js"
import style0 from "./copilot-text-area.vue?vue&type=style&index=0&id=7792fc9c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7792fc9c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VIcon,VRow,VTextarea})

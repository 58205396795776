<template>
  <div>
    <v-data-table
      :headers="header"
      :hide-default-footer="list.length <= 5"
      :footer-props="footerProps"
      :no-data-text="$t('TXT_NO_DATA_AVAILABLE')"
      :items="list"
    >
      <template v-slot:[`item.adminRoles`]="{ item }">
        <Prd-loading-circular v-if="item.isLoadingRegional" />
        <v-row v-else no-gutters align="center" justify="center" class="gap-4">
          <v-btn icon @click="handlerAdminRoles('1000', item)">
            <img
              :src="setToCountryFlag('1000')"
              :class="{ disbleFlag: !item.adminRoles.includes('1000') }"
              class="imageFlag"
              alt=""
            />
          </v-btn>
          <v-btn icon @click="handlerAdminRoles('1001', item)">
            <img
              :src="setToCountryFlag('1001')"
              :class="{ disbleFlag: !item.adminRoles.includes('1001') }"
              class="imageFlag"
              alt=""
            />
          </v-btn>
          <v-btn icon @click="handlerAdminRoles('1002', item)">
            <img
              :src="setToCountryFlag('1002')"
              :class="{ disbleFlag: !item.adminRoles.includes('1002') }"
              class="imageFlag"
              alt=""
            />
          </v-btn>
        </v-row>
      </template>
      <template v-slot:[`item.predifyAdmin`]="{ item }">
        <Prd-loading-circular v-if="item.isLoadingPredify" />
        <v-icon
          v-else
          :color="
            item.adminRoles.includes('1003') ? $prdStyles('color-error') : ''
          "
          @click="handlerAdminRoles('1003', item)"
          >mdi-shield-account-outline</v-icon
        >
      </template>
    </v-data-table>

    <Confirm-access-modal
      :user="userForEdit"
      :isModalOpen="confirmChangesModal"
      @confirmAccess="confirmAccess"
      @closeModal="confirmChangesModal = false"
    />
  </div>
</template>

<script>
import UserService from "@/service/user-service";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
import ConfirmAccessModal from "./confirm-access-modal.vue";
export default {
  components: { PrdLoadingCircular, ConfirmAccessModal },
  props: ["list"],
  data() {
    return {
      service: new UserService(),
      userForEdit: {},
      confirmChangesModal: false,
      header: [
        {
          value: "nome",
          text: this.$i18n.t("TXT_NAME"),
          align: "center",
        },
        {
          value: "email",
          text: this.$i18n.t("TXT_EMAIL"),
          align: "center",
        },
        {
          value: "adminRoles",
          text: this.$i18n.t("TXT_REGIONAL_ADMIN"),
          align: "center",
        },
      ],
      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },
    };
  },
  computed: {
    isPredifyAdmin() {
      return this.$store.getters.isPredifyAdmin;
    },
  },
  methods: {
    setToCountryFlag(value) {
      switch (value) {
        case "1000":
          return require("../../../../../public/images/icons/flags/br.svg");
        case "1001":
          return require("../../../../../public/images/icons/flags/us.svg");
        case "1002":
          return require("../../../../../public/images/icons/flags/es.svg");
        default:
          return "";
      }
    },
    setPredifyField() {
      if (!this.isPredifyAdmin) return;
      this.header.push({
        value: "predifyAdmin",
        text: "Predify Admin",
        align: "center",
      });
    },
    createRequest(level, email) {
      return {
        AccessLevel: level,
        UserEmail: email,
      };
    },
    checkLevel(level, userData) {
      return userData.adminRoles.includes(level.toString());
    },
    updateRole(level, idUsuario, action) {
      const selectedUser = this.list.find(
        (user) => user.idUsuario === idUsuario
      );

      if (action === "add") {
        if (!selectedUser.adminRoles.includes(level)) {
          selectedUser.adminRoles.push(level);
        }
      } else if (action === "remove") {
        if (selectedUser.adminRoles.includes(level)) {
          selectedUser.adminRoles = selectedUser.adminRoles.filter(
            (role) => role !== level
          );
        }
      }

      this.$emit("handlerRoles", idUsuario, selectedUser.adminRoles);
    },
    setLoading(level, userData) {
      if (level == "1000" || level == "1001" || level == "1002")
        userData.isLoadingRegional = !userData.isLoadingRegional;
      else userData.isLoadingPredify = !userData.isLoadingPredify;
    },

    handlerAdminRoles(level, userData) {
      this.confirmChangesModal = true;
      this.userForEdit = {
        level: level,
        userData: userData,
      };
    },

    async confirmAccess(data) {
      this.confirmChangesModal = false;
      let level = data.level;
      let userData = data.userData;

      this.setLoading(level, userData);
      const email = userData.email;
      const request = this.createRequest(level, email);
      const isRemove = this.checkLevel(level, userData);
      if (isRemove) await this.removeAdminRole(request, userData);
      else await this.setAdminRole(request, userData);
      this.setLoading(level, userData);
      this.userForEdit = {};
    },

    async setAdminRole(request, userData) {
      await this.service
        .SetAdminRole(request)
        .then((res) => {
          if (res.status == 200) {
            this.updateRole(request.AccessLevel, userData.idUsuario, "add");
            this.$store.commit("snackbarV2/set", {
              message: this.$i18n.t("TXT_SET_ROLE_ADD"),
              type: "success",
            });
          } else {
            this.$store.commit("snackbarV2/set", {
              message: this.$i18n.t("TXT_SET_ROLE_INFO"),
              type: "info",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("snackbarV2/set", {
            message: this.$i18n.t("TXT_SET_ROLE_ERROR"),
            type: "error",
          });
        });
    },

    async removeAdminRole(request, userData) {
      await this.service
        .RemoveAdminRole(request)
        .then((res) => {
          if (res.status == 200) {
            this.updateRole(request.AccessLevel, userData.idUsuario, "remove");
            this.$store.commit("snackbarV2/set", {
              message: this.$i18n.t("TXT_SET_ROLE_REMOVE"),
              type: "success",
            });
          } else {
            this.$store.commit("snackbarV2/set", {
              message: this.$i18n.t("TXT_SET_ROLE_INFO"),
              type: "info",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("snackbarV2/set", {
            message: this.$i18n.t("TXT_SET_ROLE_ERROR"),
            type: "error",
          });
        });
    },
  },

  mounted() {
    this.setPredifyField();
  },
};
</script>

<style lang="scss" scoped>
.imageFlag {
  width: 25px;
  border-radius: 5px;
}
.disbleFlag {
  opacity: 0.3;
}
</style>
import { render, staticRenderFns } from "./product-panel-table.vue?vue&type=template&id=7c55b549&scoped=true"
import script from "./product-panel-table.vue?vue&type=script&lang=js"
export * from "./product-panel-table.vue?vue&type=script&lang=js"
import style0 from "./product-panel-table.vue?vue&type=style&index=0&id=7c55b549&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c55b549",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VDataTable,VDialog,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VIcon,VSimpleCheckbox,VTextField})

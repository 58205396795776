import { render, staticRenderFns } from "./granularity-body.vue?vue&type=template&id=98275202&scoped=true"
import script from "./granularity-body.vue?vue&type=script&lang=js"
export * from "./granularity-body.vue?vue&type=script&lang=js"
import style0 from "./granularity-body.vue?vue&type=style&index=0&id=98275202&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98275202",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
installComponents(component, {VExpansionPanelContent})

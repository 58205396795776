import { render, staticRenderFns } from "./report-modal-main.vue?vue&type=template&id=371e3960"
import script from "./report-modal-main.vue?vue&type=script&lang=js"
export * from "./report-modal-main.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VDialog,VRow})

<template>
  <v-card  :disabled="isLoading" class="login-card" elevation="0">
    <h2 class="title my-8">{{ $t("TXT_LOG_IN") }}</h2>
    <!--E-MAIL-->
    <p class="fieldTitle">{{ $t("TXT_EMAIL") }}</p>
    <v-text-field
      :rules="[rules.required, rules.email]"
      :placeholder="$t('TXT_EMAIL_EXAMPLE')"
      outlined
      dense
      v-model="email"
      @keyup="emailToLowerCase()"
      @keypress.enter="login"
    ></v-text-field>
    <!--PASSWORD-->
    <p class="fieldTitle">{{ $t("TXT_PASSWORD") }}</p>
    <v-text-field
      :rules="[rules.required]"
      :type="showPassword ? 'text' : 'password'"
      @click:append="showPassword = !showPassword"
      @keypress.enter="login"
      :placeholder="$t('TXT_PASSWORD_PLACEHOLDER')"
      outlined
      dense
      v-model="password"
      :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
    >
    </v-text-field>
    <!--LOGIN ACTIONS-->
    <v-card-actions class="d-flex ma-0 pa-0 justify-center">
      <v-checkbox
        class="ma-0 pa-0"
        hide-details
        v-model="rememberLogin"
        required
      >
        <template slot="label">
          <span style="color: black; font-size: 14px !important">
            {{ $t("TXT_PASSWORD_REMEMBER") }}
          </span>
        </template>
      </v-checkbox>
      <v-spacer></v-spacer>
      <router-link to="password-recovery">
        <span style="font-size: 14px !important">{{
          $t("TXT_FORGOT_PASSWORD")
        }}</span>
      </router-link>
    </v-card-actions>
    <div>
      <label>{{ $t(errorCode) }}</label>
    </div>
    <v-card-actions >
      <v-row>
        <v-col cols="12" class="mt-10 d-flex justify-center pa-0">
          <div>
            <p
              v-show="showErrorMessage"
              class="pa-0 mb-2"
              style="color: #ec4c37"
            >
              {{ $i18n.t("TXT_WRONG_USER_PASSWORD") }}
            </p>
            <defaultButton
              v-show="!isLoading"
              :title="$t('TXT_LOGIN')"
              @click="login"
              :style="'width: 100%'"
            ></defaultButton>
          </div>

          <!--PROGRESS BAR-->
          <v-progress-circular
            v-if="isLoading === true"
            class="mx-auto my-8"
            :size="30"
            color="primary"
            indeterminate
          >
          </v-progress-circular>
        </v-col>
        <!-- <v-col cols="12" class="d-flex justify-center">
          <defaultButton :loading="isLoading"
            @click.native="signUp()"
            outlined
            :title="$t('TXT_REGISTER')"
          ></defaultButton>
        </v-col> -->
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import defaultButton from "@/Design_System/common/prd-btn.vue";
import LoginService from "@/service/login-service.js";
const Service = new LoginService();
export default {
  name: "sign-in-v2",
  components: {
    defaultButton,
  },
  data() {
    return {
      errorCode: null,
      password: "",
      email: "",
      rules: {
        required: (value) => !!value || this.$i18n.t("TXT_REQUIRED_2"),
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$i18n.t("TXT_INVALID_EMAIL");
        },
      },
      rememberLogin: null,
      showPassword: false,
      showEmailErrorMessage: false,
      isLoading: false,
      showErrorMessage: false,
    };
  },

  methods: {
    async login() {
      this.isLoading = true;
      try {
        const res = await Service.login.call({
          email: this.email,
          password: this.password,
        });

        let userData = {
          email: res.data.userName,
          hasUser: true,
          rememberLogin: this.rememberLogin,
          token: res.data.access_token,
          adminRoles: [],
          idCompany: null,
          id: null,
          username: null,
          phone: null,
        };

        this.$store.dispatch("setUser", userData);

        const userRoles = await Service.getUserRoles(this.email);
        userData = {
          email: res.data.userName,
          hasUser: true,
          rememberLogin: this.rememberLogin,
          token: res.data.access_token,
          adminRoles: userRoles?.data?.adminRoles || [],
          idCompany: null,
          id: userRoles?.data?.idUsuario,
          username: userRoles?.data?.nome,
          phone: userRoles?.data?.telefone,
        };

        this.$store.dispatch("setUser", userData);
        this.isLoading = false;

        window.location.reload()
      } catch (e) {
        this.showErrorMessage = true;

        setTimeout(() => {
          this.showErrorMessage = false;
        }, 4000);
        console.error(e);
        this.isLoading = false;
      }
    },

    displayPassword() {
      this.showPassword = !this.showPassword;
    },

    emailToLowerCase() {
      if (this.email) this.email = this.email.toLowerCase();
    },

    isValid() {
      if (!this.password) return false;
      if (!this.password.length >= 6) return false;
      if (!this.email) return false;
      if (!this.email.includes("@")) return false;
      if (!this.email.split("@")[1].includes(".")) return false;
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.login-card {
  width: 330px;
  margin: 0 auto;
  padding: 0;

  .title {
    text-align: center;
    font-weight: $font-weight-bold;
    font-size: $font-size-lg;
    color: $brand-color-primary-pure;
    text-transform: uppercase;
  }

  label {
    font-size: $font-size-xxs;
    font-weight: $font-weight-bold;
  }

  .fieldTitle {
    font-size: 14px !important;
    font-weight: bold !important;
    margin-bottom: 8px !important;
  }

  v-text-field {
    font-size: $font-size-xxs;
  }

  span {
    font-size: $font-size-xxxs;
  }

  a {
    font-size: $font-size-xxxs;
    text-decoration: none;
  }
}
</style>
<template>
  <v-container fluid>
    <Title-bread-crumbs
      :title="$t('TXT_ADMINISTRATIVE_ACCESS').toUpperCase()"
      :hasTooltip="false"
    />
    <v-card class="pa-4">
      <h3 class="mb-4">{{ $t("TXT_REGISTRED_USERS") }}</h3>
      <Filter-fields
        class="mb-4"
        :isLoading="isLoading"
        :originalList="listUsers"
        @filteredList="listUsersFiltered = $event"
      />
      <Prd-loading-circular v-if="isLoading" />
      <User-table
        v-else
        :isLoading="isLoading"
        :list="listUsersFiltered"
        @resetList="getAllUsers"
        @handlerRoles="handlerRoles"
      />
    </v-card>
  </v-container>
</template>

<script>
import TitleBreadCrumbs from "@/Design_System/common/title.vue";
import UserTable from "./components/user-table.vue";
import FilterFields from "./components/filter-fields.vue";
import UserService from "@/service/user-service";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
export default {
  components: {
    TitleBreadCrumbs,
    UserTable,
    FilterFields,
    PrdLoadingCircular,
  },
  data() {
    return {
      service: new UserService(),
      isLoading: false,
      listUsers: [],
      listUsersFiltered: [],
    };
  },
  methods: {
    getAllUsers() {
      this.isLoading = true;
      this.service
        .getAllUsers()
        .then((res) => {
          if (res.status == 200) {
            const list = res.data.map((user) => {
              return {
                ...user,
                isLoadingPredify: false,
                isLoadingRegional: false,
              };
            });
            this.listUsers = list;
            this.listUsersFiltered = list;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    checkUserAdmin() {
      if (!this.isPredifyAdmin) this.$router.push({ name: "home" });
      this.getAllUsers();
    },

    handlerRoles(idUsuario, newRoles) {
      const selectedUser = this.listUsers.find(
        (user) => user.idUsuario == idUsuario
      );
      selectedUser.adminRoles = newRoles;
    },
  },
  computed: {
    isPredifyAdmin() {
      return this.$store.getters.isPredifyAdmin;
    },
  },
  mounted() {
    this.checkUserAdmin();
  },
};
</script>

<style lang="scss" scoped>
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('h4',[_vm._v(_vm._s(_vm.name))]),_c('label',[_vm._v(_vm._s(_vm.subtitle))])]),_c('v-spacer'),_c('Prd-btn',{class:_vm.isAdminUpl ? 'mr-4' : '',attrs:{"title":_vm.$t('TXT_EXTRACT_FILE'),"disabled":_vm.inEditMode},on:{"click":function($event){return _vm.extractMatrix(_vm.items)}}}),(_vm.isAdminUpl)?_c('v-col',{attrs:{"cols":"auto"}},[(!_vm.inEditMode)?_c('Prd-btn',{attrs:{"title":_vm.$t('TXT_EDIT_DATA')},on:{"click":function($event){_vm.inEditMode = !_vm.inEditMode}}}):_c('v-row',{staticClass:"gap-4",attrs:{"no-gutters":"","align":"center"}},[(_vm.isLoading)?_c('span',[_vm._v(_vm._s(_vm.$t("TXT_SAVING_DATA")))]):_vm._e(),(_vm.isLoading)?_c('prd-loading-circular'):_vm._e(),(_vm.inEditMode)?_c('Prd-btn',{attrs:{"title":_vm.$t('TXT_SAVE'),"disabled":_vm.isLoading || !_vm.unlockSaveButton},on:{"click":_vm.saveEditions}}):_vm._e(),(_vm.inEditMode)?_c('Prd-btn',{attrs:{"title":_vm.$t('TXT_CANCEL'),"outlined":true,"disabled":_vm.isLoading},on:{"click":_vm.resetTable}}):_vm._e()],1)],1):_vm._e()],1),_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.headers,"items":_vm.localItems,"hide-default-footer":_vm.localItems == [] || _vm.localItems.length == 0,"footer-props":_vm.footerProps,"no-data-text":_vm.$t('TXT_NO_DATA_AVAILABLE')},scopedSlots:_vm._u([{key:"item.committee",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.committee + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.committee),callback:function ($$v) {_vm.$set(item, "committee", $$v)},expression:"item.committee"}})]}},{key:"item.commercialDirector",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.commercialDirector + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.commercialDirector),callback:function ($$v) {_vm.$set(item, "commercialDirector", $$v)},expression:"item.commercialDirector"}})]}},{key:"item.businessUnit",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.businessUnit + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.businessUnit),callback:function ($$v) {_vm.$set(item, "businessUnit", $$v)},expression:"item.businessUnit"}})]}},{key:"item.regional",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.regional + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.regional),callback:function ($$v) {_vm.$set(item, "regional", $$v)},expression:"item.regional"}})]}},{key:"item.ctc",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.ctc + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.ctc),callback:function ($$v) {_vm.$set(item, "ctc", $$v)},expression:"item.ctc"}})]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.setTotal(item)))])]}},{key:"item.portifolioStatus",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(_vm.setPortifolioStatus(item.portifolioStatus)))]):_c('Prd-combo-box',{attrs:{"items":_vm.portifolioStatusItems,"disabled":_vm.isLoading},model:{value:(item.portifolioStatus),callback:function ($$v) {_vm.$set(item, "portifolioStatus", $$v)},expression:"item.portifolioStatus"}})]}},{key:"item.check",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.check + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.check),callback:function ($$v) {_vm.$set(item, "check", $$v)},expression:"item.check"}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
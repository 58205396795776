<template>
  <v-row no-gutters>
    <v-col cols="12">
      <span style="display: flex; align-items: center">
        <span>{{ $t('TXT_REFERENCE_PERIOD') }}</span>
        <Tooltip-informations
          :title="$t('TXT_TOOLTIP_REFERENCE_PERIOD')"
          :rightIcon="true"
        />
      </span>
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        ref="menu"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateRangeText"
            v-bind="attrs"
            v-on="on"
            :label="$t('TXT_SELECT_PERIOD')"
            :placeholder="$t('TXT_SELECT_PERIOD')"
            :disabled="externalDisabled"
            hide-details
            outlined
            single-line
            dense
            readonly
            class="input-datapicker mt-1"
          >
            <template v-slot:append>
              <v-icon
                :class="`append-calendar ${
                  externalDisabled ? 'disabled-calendar' : ''
                }`"
                color="white"
                small
              >
                mdi-calendar</v-icon
              >
            </template>
          </v-text-field>
        </template>
        <v-date-picker v-model="date" no-title scrollable range :max="maxDate">
          <v-spacer></v-spacer>

          <v-btn
            text
            color="error"
            @click="cancelSelection"
            v-t="'TXT_CANCEL'"
          ></v-btn>
          <v-btn outlined color="primary" @click="date = []">Reset</v-btn>

          <v-btn
            color="primary"
            @click="$refs.menu.save(date)"
            v-t="'TXT_OK'"
          ></v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import TooltipInformations from "@/Design_System/common/tooltip-informations.vue";
export default {
  components: {
    TooltipInformations,
  },
  props: {
    modelValue: {
      type: Array,
      require: true,
      default: () => [],
    },
    externalDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      date: [],
      dateRangeText: null,
    };
  },
  computed: {
    maxDate() {
      return new Date().toISOString().split("T")[0];
    },
  },
  methods: {
    cancelSelection() {
      this.date = [];
      this.menu = false;
    },
  },

  watch: {
    date: {
      handler(value) {
        if (value.length == 0) return;
        this.dateRangeText = value.join(" ~ ");
        this.$emit("input", value);
      },
      deep: true,
      immediate: true,
    },
    modelValue: {
      handler(value) {
        this.date = value;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.input-datapicker .v-input__app end-inner {
  margin-top: 0px !important;
}

.append-calendar {
  height: 40px;
  width: 40px;
  background: #3c5ca7;
  margin-right: -11px;
  border-radius: 0px 4px 4px 0px;
  color: white;
}
.disabled-calendar {
  background: #e0e0e0;
}
</style>
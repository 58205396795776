<template>
  <v-dialog v-model="modalOpen" :max-width="600" persistent>
    <v-card class="pa-4">
      <div class="my-flex">
        <v-icon :color="$prdStyles('color-success')" size="35"
          >mdi-check-circle-outline</v-icon
        >
        <h4 class="my-4">{{ $t("TXT_CONFIRM_USER_ACCESS_CHANGES") }}</h4>
        <span>{{ $t("TXT_CONFIRM_USER_ACCESS_CHANGES_DETAIL") }}</span>
      </div>

      <div class="group">
        <span>
          {{ $t("TXT_NAME") }}:
          <span class="font-weight-bold">{{ userName }}</span>
        </span>
        <span>
          {{ $t("TXT_EMAIL") }}:
          <span class="font-weight-bold">{{ userEmail }}</span>
        </span>

        <v-row no-gutters align="center"
          ><span class="mr-2">{{ $t("TXT_ACCESS") }}:</span>

          <v-row v-if="user.level == '1003'" no-gutters align="center">
            <span class="font-weight-bold">Admin</span>
            <v-icon :color="$prdStyles('color-primary')" class="ml-2">mdi-shield-account-outline</v-icon>
          </v-row>

          <v-row v-else no-gutters align="center">
            <span class="font-weight-bold">{{ setUserString(user.level) }}</span>
            <img :src="setToCountryFlag(user.level)" class="imageFlag ml-2" />
          </v-row>
        </v-row>
      </div>

      <span>{{ $t("TXT_CONFIRM_USER_ACCESS_INFO") }}</span>
      <Prd-card-actions
        :outlinedTitle="$t('BTN_CANCEL')"
        :title="$t('BTN_CONFIRM')"
        :loading="isLoading"
        :isDisable="isLoading"
        @clickOutlined="cancelChanges"
        @click="confirmAccess"
      />
    </v-card>
  </v-dialog>
</template>
<script>
import PrdCardActions from "@/components/common/prd-card-actions.vue";

export default {
  components: { PrdCardActions },
  props: [
    "group",
    "isLoading",
    "name",
    "description",
    "resources",
    "isModalOpen",
    "user",
  ],
  data() {
    return {
      modalOpen: false,
    };
  },

  computed: {
    userName() {
      return this.user?.userData?.nome ?? null;
    },
    userEmail() {
      return this.user?.userData?.email ?? null;
    },
  },
  watch: {
    isModalOpen: {
      handler(value) {
        this.modalOpen = value;
      },
    },
  },
  methods: {
    setToCountryFlag(value) {
      switch (value) {
        case "1000":
          return require("../../../../../public/images/icons/flags/br.svg");
        case "1001":
          return require("../../../../../public/images/icons/flags/us.svg");
        case "1002":
          return require("../../../../../public/images/icons/flags/es.svg");
        default:
          return "";
      }
    },

    setUserString(level) {
      switch (level) {
        case "1000":
          return this.$i18n.t("TXT_BRAZIL");
        case "1001":
          return this.$i18n.t("TXT_UNITED_STATES");
        case "1002":
          return this.$i18n.t("TXT_EUROPE");
      }
    },

    confirmAccess() {
      this.$emit("confirmAccess", this.user);
    },
    cancelChanges() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-bottom: 16px;
}
.destaq-color {
  color: $feedback-color-warning-pure;
}

.user-info {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
}
.align-center {
  text-align: center;
}

.group {
  background-color: $neutral-color-high-light;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.imageFlag {
  width: 25px;
  border-radius: 5px;
}
</style>
import axiosInstance, {
  API_URL_HOME_20,
  API_URL,
} from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";
import store from "@/store";

export default class Home20Service extends ServiceBase {
  async getAcaoPromocional(idCompany) {
    return await axiosInstance.get(
      API_URL_HOME_20 + `api/AcaoPromocional?idCompany=${idCompany}`
    );
  }

  async checkAdm(store) {
    return await axiosInstance.post(API_URL_HOME_20 + "api/Admin", store);
  }

  async createAcaoPromocional(acaoPromocional) {
    return await axiosInstance.post(
      API_URL_HOME_20 + `api/AcaoPromocional`,
      acaoPromocional,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  async getAlert(idCompany, idPriceGroup) {
    return await axiosInstance.get(
      API_URL_HOME_20 +
      `api/Alert?idCompany=${idCompany}&idPriceGroup=${idPriceGroup}`
    );
  }

  async getRecipe(loja, curva, categoria) {
    return await axiosInstance.get(
      API_URL_HOME_20 +
      `api/Recipe?loja=${loja}&curva=${curva}&categoria=${categoria}`
    );
  }

  async getRecipeByPricing(idCompany, idPriceGroup) {
    return await axiosInstance.get(
      API_URL +
      `api/v2/Enterprise/PriceGroups/SceneryInfo?idCompany=${idCompany}&idPriceGroup=${idPriceGroup}&curve=&status=`
    );
  }

  async getPricingGroup(idPriceGroup) {
    return await axiosInstance.get(
      `https://dataanalyticsapi.azurewebsites.net/vem/price_group`,
      { params: { affiliate: idPriceGroup, max_period: 45 } }
    );
  }

  async getGraficos(loja, curva, categoria) {
    return await axiosInstance.get(
      API_URL_HOME_20 +
      `api/Grafico?loja=${loja}&curva=${curva}&categoria=${categoria}`
    );
  }

  async getDataGraphicRevenue(idCompany, loja) {
    return await axiosInstance.get(
      API_URL_HOME_20 +
      `api/Grafico/GetGraficoReceita?idCompany=${idCompany}&loja=${loja}`
    );
  }

  async getDataGraphicRevenueV2(idCompany, affiliate) {
    return await axiosInstance.post(
      API_URL +
      `api/v2/Enterprise/SalesHistory/GetListGraphicRevenue?idCompany=${idCompany}&affiliate=${affiliate}`
    );
  }

  async getOportunities(idCompany, idPriceGroup) {
    return await axiosInstance.get(
      API_URL_HOME_20 +
      `api/Oportunities?idCompany=${idCompany}&idPriceGroup=${idPriceGroup}`
    );
  }

  async getAffiliateFilter(idCompany, preSelect = true) {
    return await axiosInstance.get(
      API_URL +
      `api/V2/Enterprise/PriceGroups/Filter?idCompany=${idCompany}&preSelect=${preSelect}&showInApriori=false&showInPricing=true`
    );
  }

  async getFilters() {
    const auth = store.getters.userData;
    return await axiosInstance.get(
      API_URL_HOME_20 + `api/Filters?token=${auth.token.token}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  async getRelatorioAcaoPromocional(idCompany) {
    return await axiosInstance.get(
      API_URL_HOME_20 + `api/AcaoPromocional/relatorio?idCompany=${idCompany}`,
      { responseType: "blob" }
    );
  }

  async getLastMonthProjection(loja, curva, categoria) {
    return await axiosInstance.get(
      API_URL_HOME_20 +
      `api/Recipe/lastMonth?loja=${loja}&curva=${curva}&categoria=${categoria}`
    );
  }

  async getListPriceGroups(idCompany, filters) {
    return await axiosInstance.post(
      API_URL + `api/v2/Enterprise/PriceGroups/List`,
      { idCompany, filters, isSimulation: false }
    );
  }

  async GetAllFilterCategories() {
    return await axiosInstance.get(
      API_URL_HOME_20 + "/api/GraficoIC/GetAllFilterCategories"
    );
  }

  async GetGraficoIC(data) {
    return await axiosInstance.get(
      API_URL_HOME_20 +
      `api/GraficoIC/GetGraficoIC?category=${data.category}&compType=${data.compType}&state=${data.state}&period=${data.period}&coordinator=${data.coordinator}`
    );
  }

  async GetStateByIdPriceGroup(idPriceGroup) {
    return await axiosInstance.get(
      API_URL_HOME_20 +
      `api/GraficoIC/GetStateByIdGroup?idPriceGroup=${idPriceGroup}`
    );
  }

  async GetListedPrice(idCompany, state, affiliate) {
    return await axiosInstance.get(
      API_URL_HOME_20 +
      `api/ProdutosTabelados?idCompany=${idCompany}&state=${state}&affiliate=${affiliate}`
    );
  }

  async getIsAdminVem() {
    return await axiosInstance.get(API_URL + `api/Usuario/IsAdminVem`);
  }

  async getDataScatterPlot(idStore) {
    return await axiosInstance.get(
      API_URL_HOME_20 +
      `api/GraficoMargemMedia/GetGraficoMargem?loja=${idStore}`
    );
  }

  async GetMarginByIdCompany(idCompany) {
    return await axiosInstance.get(
      API_URL_HOME_20 +
      `api/GraficoIC/GetMarginByIdCompany?idCompany=${idCompany}`
    );
  }

  async getLastApprovedPrecification(idCompany) {
    return await axiosInstance.get(
      API_URL +
      `api/v2/Enterprise/PriceGroups/LastApprovedGroup?idCompany=${idCompany}`
    );
  }

  async getPrecificationReport(request) {
    return await axiosInstance.post(
      API_URL +
      `api/v2/Enterprise/PriceGroups/ExportGroups`, request,
      { responseType: 'blob' }
    );
  }
}
